import { useEffect, useState, forwardRef } from "react";
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router";

import { resetToastMessage } from "../../../store/features/global/global";

function SnackBar(props) {
    const [open, setOpen] = useState(true);
    const [message, setMessage] = useState((props && props.message) || "");
    const [anchorOrigin] = useState(
        (props && props.anchorOrigin) || {
            vertical: "top",
            horizontal: "right",
        }
    );
    const [alertType, setAlertType] = useState(
        (props && props.alertType) || "info"
    );

    let location = useLocation();

    useEffect(() => {
        if (props.alertType !== alertType) {
            setAlertType(props.alertType);
        }
    }, [props.alertType, alertType]);

    useEffect(async () => {
        if (message !== props.message) {
            await setOpen(false);
            setMessage(props.message);
            setOpen(true);
        }
    }, [props.message, message]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        props.resetToastMessage();
        setOpen(false);
        setMessage("");
    };

    const MuiAlert = forwardRef(function MuiAlert(props, ref) {
        return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    useEffect(() => {
        if (alertType !== "error")
            setTimeout(() => {
                handleClose();
            }, 5000);
    }, []);

    useEffect(() => {
        const lowerMessage = message.toLowerCase();
        if (
            location.pathname === "/" &&
            !lowerMessage.includes("expir") &&
            !lowerMessage.includes("invalid token") &&
            !lowerMessage.includes("not found") &&
            !lowerMessage.includes("server") &&
            !lowerMessage.includes("incorrect") &&
            !lowerMessage.includes("sso")
        ) {
            handleClose();
        }    
    }, [location]);

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={alertType === "error" ? null : 5000}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
            >
                <MuiAlert
                    onClose={handleClose}
                    severity={alertType}
                    sx={{ width: "100%" }}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetToastMessage: () => dispatch(resetToastMessage()),
    };
};

export default connect(null, mapDispatchToProps)(SnackBar);
