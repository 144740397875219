import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";

import LoginForm from "./components/LoginForm";
import MarketingBanner from "./components/MarketingBanner";
import { signIn } from "../../store/features/auth/auth";

import logo from "../../assets/images/header_logo.png";

import styles from "./Login.module.scss";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inputError, setInputError] = useState({});

  const token = useSelector((state) => state.auth.token);

  if (token) {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectURL = searchParams.get("redirectURL");
    if (redirectURL) {
      window.location.href = redirectURL;
    } else {
      navigate("/workbench");
    }
  }

  const onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const onResetPassword = () => {};

  const onSubmit = (event) => {
    event.preventDefault();

    let error = {};

    if (!password?.length) {
      error.password = "Please enter password";
    }

    if (Object.keys(error).length) {
      setInputError(error);
    } else {
      dispatch(
        signIn({
          email,
          password,
        })
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={`${styles["signin-form__header"]}`}>
          <img src={logo} alt="logo" className={`${styles["header__logo"]}`} />
          <Typography
            component="h1"
            variant="h2"
            className={`${styles["brand__title"]}`}
          >
            Smart Platform
          </Typography>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div
          id="signInForm"
          className={`${styles["h-md-100"]} ${styles["signin-form-container"]}`}
        >
          <div className={`${styles["login-options"]}`}>
            <div className={`${styles["login__wrapper"]}`}>
              <Typography
                id="signInText"
                className={`${styles["sign-in-text"]}`}
                variant="h2"
              >
                Sign in to your account.
              </Typography>
              <LoginForm
                email={email}
                password={password}
                onChange={onChange}
                onResetPassword={onResetPassword}
                onSubmit={onSubmit}
                error={inputError}
              />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={6}>
        <MarketingBanner />
      </Grid>
    </Grid>
  );
}

export default Login;
