import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    Tooltip,
    IconButton,
    Modal,
    Switch,
    Menu,
    MenuItem,
    styled,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import { AgGridReact } from "ag-grid-react";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, isEmpty } from "lodash";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";

import { ReactComponent as Unlocked } from "../../../assets/images/lock_open_right.svg";
import { ReactComponent as PartialLocked } from "../../../assets/images/partial_lock.svg";
import Select from "../../common/filters/Select/Select";
import {
    LogValueRenderer,
    PriceValueRenderer,
    attributeOptions,
    countryLevelPriceCols,
} from "./EUPricePlanningTableHelpers";
import { CustomHeader } from "../priceRules/create/step6PriceRules/step6Helper";
import { fallbackFormatter } from "../../../utils/helpers/formatter";
import {
    requestComplete,
    requestFail,
    requestStart,
    setTriggerUnsavedEdit,
    toastError,
    toastMessage,
    toastSuccessMessage,
} from "../../../store/features/global/global";
import {
    downloadAuditLogData,
    fetchAuditLogData,
    getPriceFileMaterialsAndCountryLevelPrices,
    toggleLockAPI,
    saveCountryLevelPrices,
    setCountryLevelPrices,
    setUserResizedColumns,
    syncToGFE,
    createApprovalExcel,
    approvalMailRequest,
    changeToAMFFunc,
    setMaterials,
} from "../../../store/features/workbench/workbench";
import {
    APPROVAL_STATUS,
    alertAndExceptionOptions,
    auditLogDownloader,
    checkIfMaterialProcessedForCountryPrice,
    checkMaterialApprovalStatus,
    checkMaterialSelectionValidity,
    filterAndMapColumnChannelWise,
    getMaterialFetchData,
    isMaterialBeingSynced,
} from "./euHelper";
import TableRowCount from "../../common/tableRowCount/TableRowCount";
import useTableRowCount from "../../common/tableRowCount/useTableRowCount";
import {
    getColPinValue,
    isMaterialWithoutMandatoryException,
    rightPinCellStyle,
} from "./EUPriceListLevelPrices";
import AgGridHeaderRenderer from "../../../utils/reactTable/cellRenderers/AgGridHeaderRenderer";
import LoadingOverlay from "../../ui/loader/Loader";
import TableSettings, {
    tableSettingColumnWidthModes,
    tableSettingTabIds,
} from "./TableSettings";
import { getMaterialLockStatus } from "./MaterialAlertException";
import { PRICING_TEAM, SUPER_USER } from "../exceptionConfig/ExceptionConfig";
import ClearAllFilterButton from "../../common/clearAllFilterButton/ClearAllFilterButton";
import { numberColFilterParams } from "../../../utils/helpers/utility_helpers";

import "../../../utils/reactTable/tableRenderer/AgGridTable.scss";
import styles from "./EUPricePlanning.module.scss";
import pageStyles from "./EUCountryLevelPrices.module.scss";

export const syncStatusCodes = {
    0: {
        text: "Not Synced",
        backgroundColor: "#FABD4D",
    },
    1: {
        text: "Syncing",
        backgroundColor: "#0457B0",
    },
    2: {
        text: "Synced",
        backgroundColor: "#24A148",
    },
    3: {
        text: "Sync Error",
        backgroundColor: "#D91E28",
    },
};

export const outletNameReplacementMapping = {
    "dtc actual margin": "ACTUAL MARGIN",
    "dtc actual sales": "ACTUAL SALES",
    "dtc actual units": "ACTUAL UNITS",
    "dtc margin %": "MARGIN %",
    "dtc projection margin": "PROJECTION MARGIN",
    "dtc projection sales": "PROJECTION SALES",
    "dtc projection units": "PROJECTION UNITS",
    "euro standard msrp": "PFS WAS (EUR)",
};

export const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(
    () => ({
        "& .MuiPaper-root": {
            borderRadius: 6,
            padding: 0,
            boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        },
    })
);

export const approvalReqTypes = {
    SEND_NOW: "0",
    SCHEDULED_TIME: "1",
};

export const approvalStatus = {
    UNAPPROVED: { value: 0, label: "Unapproved" },
    PENDING_APPROVAL: { value: 1, label: "Pending Approval" },
    APPROVED: { value: 2, label: "Approved" },
    REJECTED: { value: 3, label: "Rejected" },
    AMF_UNAPPROVED: { value: 4, label: "AMF Unapproved" },
    AMF_PENDING_APPROVAL: { value: 5, label: "AMF Pending Approval " },
    AMF_APPROVED: { value: 6, label: "AMF Approved" },
    AMF_REJECTED: { value: 7, label: "AMF Rejected " },
};

const calculateColsToBeAutosized = (
    appliedRegionalTableSettings,
    appliedPriceListTableSettings,
    userResizedColumns,
    event
) => {
    const colNamesWithWidth = [];

    if (
        appliedRegionalTableSettings?.view_data.columnWidth ===
        tableSettingColumnWidthModes.CUSTOM
    ) {
        const colKeysWithWidth = Object.keys(
            appliedRegionalTableSettings.view_data.widths
        );

        colNamesWithWidth.push.apply(
            colNamesWithWidth,
            appliedRegionalTableSettings.view_data.columns
                .filter((col) => colKeysWithWidth.includes(col.id))
                .map((col) => col.name.toLowerCase())
        );
    }

    if (appliedRegionalTableSettings) {
        const userResizedColKeysWithWidth = Object.keys(
            userResizedColumns[tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS]
        );

        const userResizedColNamesWithWidth =
            appliedRegionalTableSettings.view_data.columns
                .filter((col) => userResizedColKeysWithWidth.includes(col.id))
                .map((col) => col.name.toLowerCase());

        for (const colName of userResizedColNamesWithWidth) {
            if (!colNamesWithWidth.includes(colName)) {
                colNamesWithWidth.push(colName);
            }
        }
    }

    const priceListColIdsWithWidth = [];

    if (
        appliedPriceListTableSettings?.view_data.columnWidth ===
        tableSettingColumnWidthModes.CUSTOM
    ) {
        priceListColIdsWithWidth.push.apply(
            priceListColIdsWithWidth,
            Object.keys(appliedPriceListTableSettings.view_data.widths)
        );
    }

    if (appliedPriceListTableSettings) {
        const userResizedColKeysWithWidth = Object.keys(
            userResizedColumns[tableSettingTabIds.PRICE_LIST_SETTINGS]
        );

        for (const colId of userResizedColKeysWithWidth) {
            if (!priceListColIdsWithWidth.includes(colId)) {
                priceListColIdsWithWidth.push(colId);
            }
        }
    }

    let cols = event.columnApi?.columnModel?.columnDefs
        .slice(8)
        .filter(
            (col) =>
                !colNamesWithWidth.includes(col.headerName.toLowerCase()) &&
                !priceListColIdsWithWidth.includes(col.field)
        )
        .map((col) => col.field);
    const firstPriceCol = cols.findIndex(
        (e) => e?.substr(e.length - 20) === "msrp_generated_value"
    );
    cols = cols.slice(0, firstPriceCol - 1);

    return cols;
};

const getNextScheduledTime = () => {
    const currentUTC = moment().utc();
    const date = currentUTC.get("date");
    const month = currentUTC.get("months") + 1;
    const year = currentUTC.get("year");
    const currentDayScheduledTime = moment(
        `${month}-${date}-${year} 06:30:00 GMT+0000`
    );

    return currentDayScheduledTime.isAfter(currentUTC)
        ? formatTimeStringForBackEnd(
              currentDayScheduledTime.utc().utcOffset("+0200").toString()
          ) // Convert to CET - 2hrs ahead of UTC
        : formatTimeStringForBackEnd(
              currentDayScheduledTime
                  .utc()
                  .add(1, "day") // Scheduling for next day as current time is later than current day's scheduling time
                  .utc()
                  .utcOffset("+0200") // Convert to CET - 2hrs ahead of UTC
                  .toString()
          );
};

const getCurrentTimeForSchedule = () => {
    return formatTimeStringForBackEnd(
        moment().utc().utcOffset("+0200").toString()
    );
};

let isContinueEditing = false;

const formatTimeStringForBackEnd = (timeString) => {
    const timeStringArr = timeString.split(" ");

    timeStringArr[0] += ",";

    const temp = timeStringArr[1];
    timeStringArr[1] = timeStringArr[2];
    timeStringArr[2] = temp;

    timeStringArr[5] = timeStringArr[5].replace("GMT", "");

    return timeStringArr.join(" ");
};

function EUCountryLevelPrices(props) {
    const { page, filterPage, currentTab } = props;

    const gridRef = useRef();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const [isAnyFilterApplied, setIsAnyFilterApplied] = useState(false);

    const downloadMenuOpen = Boolean(anchorEl);

    const {
        global: {
            userPermissions: { allowed_feature_actions },
            compLoading: {
                basePricing: basePricingLoader,
                regionalPricing: regionalPricingLoader,
                regionalPricingAlertsConfig: regionalPricingAlertsConfigLoader,
            },
            triggerUnsavedEdit,
        },
        workbench: {
            general: {
                selectedMarkets,
                selectedRegions,
                selectedChannels,
                priceFileId,
                countryPriceAlertsAndExceptions,
                countryPriceRequestMaterials,
                priceLists,
                columnSettings,
                userResizedColumns,
                selectedViewIndex,
                approverEmails,
                ccEmails,
            },
            [page]: {
                countryLevelPrices: originalData,
                selectedPlanGroup,
                materials,
            },
        },
    } = useSelector((state) => state);
    const channelLabel = selectedChannels[0].label;
    const selectedFilters = useSelector(
        (state) => state.filters.savedFilters[filterPage]
    );
    const { allowed_channel_hierarchies, role_details, allowed_regions } =
        useSelector((state) => state.global.userPermissions);

    const [selectedRows, setSelectedRows] = useState([]);

    // STATE FOR AUDIT LOG MODAL
    const [isAuditLogModalOpen, setIsAuditLogModalOpen] = useState(false);
    const [auditLogSrc, setAuditLogSrc] = useState({});

    const [editMode, setEditMode] = useState(false);
    const [changeDiff, setChangeDiff] = useState(false);
    const [data, setData] = useState([]);
    const [confirmGFESyncModalOpen, setConfirmGFESyncModalOpen] =
        useState(false);

    const [
        rowCount,
        selectedRowCount,
        onTableModelUpdate,
        onTableSelectChange,
    ] = useTableRowCount();
    const [filterModel, setFilterModel] = useState({});
    const [showFlag, setShowFlag] = useState(false);
    const [confirmGFEText, setConfirmGFEText] = useState("");

    const [exceptionOptions, setExceptionOptions] = useState([]);
    const [selectedExceptionOptions, setSelectedExceptionOptions] = useState(
        []
    );
    const [alertOptions, setAlertOptions] = useState([]);
    const [selectedAlertOptions, setSelectedAlertOptions] = useState([]);
    const [selectedLockStatusOptions, setSelectedLockStatusOptions] = useState(
        []
    );

    // toggle lock prices
    const [lockPayload, setLockPayload] = useState({});
    const [isLockModalOpen, setIsLockModalOpen] = useState(false);
    const [toggleLockConfirmationText, setToggleLockConfirmationText] =
        useState("");

    // Table Settings
    const [appliedRegionalTableSettings, setAppliedRegionalTableSettings] =
        useState(null);
    const [appliedPriceListTableSettings, setAppliedPriceListTableSettings] =
        useState(null);
    const [showTableSettings, setShowTableSettings] = useState(false);

    // Send for Approval
    const [showSendForApproval, setShowSendForApproval] = useState(false);
    const [snapshotName, setSnapshotName] = useState("");
    const [snapshotNameTrimmed, setSnapshotNameTrimmed] = useState("");
    const [selectedApproverMailIDOptions, setSelectedApproverMailIDOptions] =
        useState([]);
    const [selectedCCMailIDOptions, setSelectedCCMailIDOptions] = useState([]);
    const [approvalReqDesc, setApprovalReqDesc] = useState("");
    const [approvalReqDescTrimmed, setApprovalReqDescTrimmed] = useState("");
    const [approvalReqType, setApprovalReqType] = useState(
        approvalReqTypes.SEND_NOW
    );
    const [
        areSendForApprovalInputsInvalid,
        setAreSendForApprovalInputsInvalid,
    ] = useState(false);

    // Bulk Edit State
    const [bulkEditModalOpen, setBulkEditModalOpen] = useState(false);
    const [bulkEditRtlPriceLists, setBulkEditRtlPriceLists] = useState([]);
    const [bulkEditWhslPriceLists, setBulkEditWhslPriceLists] = useState([]);
    const [bulkEditRtlValue, setBulkEditRtlValue] = useState(1);
    const [bulkEditWhslValue, setBulkEditWhslValue] = useState(1);
    const [bulkEditConfirmModalOpen, setBulkEditConfirmModalOpen] =
        useState(false);

    const lockStatusOptions = useMemo(() => {
        return [
            { value: "locked", label: "Fully locked" },
            { value: "partial", label: "Partially locked" },
            { value: "unlocked", label: "Unlocked" },
        ];
    }, []);

    useEffect(() => {
        onTableSettingsApply(
            tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS,
            columnSettings[tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS][
                selectedViewIndex[
                    tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS
                ]
            ]
        );

        onTableSettingsApply(
            tableSettingTabIds.PRICE_LIST_SETTINGS,
            columnSettings[tableSettingTabIds.PRICE_LIST_SETTINGS][
                selectedViewIndex[tableSettingTabIds.PRICE_LIST_SETTINGS]
            ]
        );
    }, []);

    useEffect(() => {
        const alerts = {};
        const exceptions = {};

        const [alertOptions, exceptionOptions] = alertAndExceptionOptions(
            countryPriceAlertsAndExceptions,
            alerts,
            exceptions
        );

        const alertOptionLabels = alertOptions.map((ao) => ao.label);
        const exceptionOptionLabels = exceptionOptions.map((ao) => ao.label);

        setSelectedAlertOptions((prev) =>
            prev.filter((e) => alertOptionLabels.includes(e.label))
        );
        setSelectedExceptionOptions((prev) =>
            prev.filter((e) => exceptionOptionLabels.includes(e.label))
        );

        setAlertOptions(alertOptions);
        setExceptionOptions(exceptionOptions);
    }, [countryPriceAlertsAndExceptions]);

    useEffect(() => {
        setSnapshotName("");
        setSelectedApproverMailIDOptions([]);
        setSelectedCCMailIDOptions([]);
        setApprovalReqDesc("");
        setApprovalReqType(approvalReqTypes.SEND_NOW);
    }, [showSendForApproval]);

    useEffect(() => {
        const newSnapshotNameTrimmed = snapshotName.trim();

        setSnapshotNameTrimmed(newSnapshotNameTrimmed);
    }, [snapshotName]);

    useEffect(() => {
        const newApprovalReqDescTrimmed = approvalReqDesc.trim();

        setApprovalReqDescTrimmed(newApprovalReqDescTrimmed);
    }, [approvalReqDesc]);

    useEffect(() => {
        const newAreSendForApprovalInputsInvalid =
            !snapshotNameTrimmed ||
            snapshotNameTrimmed.length > 255 ||
            !selectedApproverMailIDOptions.length ||
            !approvalReqDescTrimmed;

        setAreSendForApprovalInputsInvalid(newAreSendForApprovalInputsInvalid);
    }, [
        snapshotNameTrimmed,
        selectedApproverMailIDOptions,
        approvalReqDescTrimmed,
    ]);

    const isMaterialSelectable = useCallback(
        (data) => {
            return (
                !checkIfMaterialProcessedForCountryPrice(
                    data.id,
                    countryPriceRequestMaterials
                ) &&
                checkMaterialSelectionValidity(
                    role_details,
                    allowed_regions,
                    allowed_channel_hierarchies,
                    data
                ) &&
                checkMaterialApprovalStatus(data)
            );
        },
        [
            role_details,
            allowed_regions,
            allowed_channel_hierarchies,
            countryPriceRequestMaterials,
        ]
    );

    const getUsableDataFromRedux = useCallback(
        (showErrorFlag) => {
            let storeMaterialsClone = cloneDeep(originalData);

            const x = storeMaterialsClone.map((e) => ({
                ...e,
                ...e.country_data,
                status: 2,
                errorStatus: 2,
            }));
            const order = { 0: 3, 1: 2, 3: 1, 2: 0 };

            x.forEach((e) => {
                const tempKeys = Object.keys(e.country_data);

                tempKeys.forEach((key) => {
                    if (e.country_data[key].sync_with_gfe_status === null)
                        e.country_data[key].sync_with_gfe_status = 0;
                    else if (e.country_data[key].sync_with_gfe_status === 3)
                        e.errorStatus = 3;

                    if (
                        order[e.status] <
                        order[e.country_data[key].sync_with_gfe_status]
                    )
                        e.status = e.country_data[key].sync_with_gfe_status;
                });

                delete e.country_data;
            });

            x.sort((mat1, mat2) => {
                const isMat1Accessible = checkMaterialSelectionValidity(
                    role_details,
                    allowed_regions,
                    allowed_channel_hierarchies,
                    mat1
                );
                const isMat2Accessible = checkMaterialSelectionValidity(
                    role_details,
                    allowed_regions,
                    allowed_channel_hierarchies,
                    mat2
                );

                if (!isMat1Accessible && isMat2Accessible) return 1;

                const { material_number: mn1, season_code: ms1 } = mat1;
                const { material_number: mn2, season_code: ms2 } = mat2;
                if (mn1 < mn2) return -1;
                if (mn1 > mn2) return 1;
                if (ms1 > ms2) return 1;
                return -1;
            });

            return !showErrorFlag ? x : x.filter((e) => e.errorStatus === 3);
        },
        [
            originalData,
            role_details,
            allowed_regions,
            allowed_channel_hierarchies,
        ]
    );

    const countryLevelPrices = useMemo(() => {
        return getUsableDataFromRedux(showFlag);
    }, [getUsableDataFromRedux, showFlag]);

    const selectedLockOptionsStatusCalc = useCallback((selectedOptions) => {
        let status;
        // status is divided into three sets
        // each set is made of complementary values
        // only partial is opposite of lock & unlock [{5}, {6}]
        // only lock is opposite of unlock & partial [{1}, {2}]
        // only unlock is opposite of lock & partial [{3}, {4}]
        if (selectedOptions.length === 1) {
            if (selectedOptions[0].value === "partial") status = 5;
            else if (selectedOptions[0].value === "locked") status = 1;
            else status = 3;
        } else {
            if (!selectedOptions.map((e) => e.value).includes("partial"))
                status = 6;
            else if (!selectedOptions.map((e) => e.value).includes("locked"))
                status = 2;
            else status = 4;
        }
        return status;
    }, []);
    useEffect(() => {
        gridRef?.current?.api?.onFilterChanged();
    }, [
        selectedLockStatusOptions,
        selectedExceptionOptions,
        selectedAlertOptions,
    ]);
    const externalFilter = ({ data }) => {
        if (
            selectedLockStatusOptions.length &&
            selectedLockStatusOptions.length !== 3
        ) {
            const allowedPriceListsForLock =
                appliedPriceListTableSettings?.view_data?.columns
                    ?.filter((e) => e.isSelected)
                    .map((e) => e.name);

            let status = selectedLockOptionsStatusCalc(
                selectedLockStatusOptions
            );

            const values = Object.values(data)?.filter(
                (val) => val?.price_file_generated_price_uid
            );
            if (status < 3) {
                const isAnyUnlocked = values?.find(
                    (val) =>
                        allowedPriceListsForLock.includes(
                            val.price_list_name
                        ) &&
                        (+val.is_msrp_locked === 0 || +val.is_whsl_locked === 0)
                );
                if (isAnyUnlocked && status === 1) return false;
                else if (!isAnyUnlocked && status !== 1) return false;
            } else if (status < 5) {
                const isAnyLocked = values?.find(
                    (val) =>
                        allowedPriceListsForLock.includes(
                            val.price_list_name
                        ) &&
                        (+val.is_msrp_locked === 1 || +val.is_whsl_locked === 1)
                );
                if (isAnyLocked && status === 3) return false;
                else if (!isAnyLocked && status !== 3) return false;
            } else {
                const isAnyLocked = values?.find(
                    (val) =>
                        allowedPriceListsForLock.includes(
                            val.price_list_name
                        ) &&
                        (+val.is_msrp_locked === 1 || +val.is_whsl_locked === 1)
                );
                if (!isAnyLocked) {
                    if (status === 5) return false;
                } else {
                    const isAnyUnlocked = values?.find(
                        (val) =>
                            allowedPriceListsForLock.includes(
                                val.price_list_name
                            ) &&
                            (+val.is_msrp_locked === 0 ||
                                +val.is_whsl_locked === 0)
                    );
                    if (!isAnyUnlocked) {
                        if (status === 5) return false;
                    } else if (status !== 5) return false;
                }
            }
        }

        if (selectedExceptionOptions.length) {
            const exceptionsMap = {};
            for (const exceptionOption of selectedExceptionOptions) {
                exceptionsMap[exceptionOption.value] = true;
            }

            const { material_number, season_code } = data;
            const key = material_number + season_code;
            const materialAlertAndExceptions =
                countryPriceAlertsAndExceptions[key];

            return selectedExceptionOptions.some((selecetdException) => {
                if (materialAlertAndExceptions?.length) {
                    return materialAlertAndExceptions.some(
                        (exception) =>
                            exception.entry_type === 2 &&
                            exception.id === selecetdException.value
                    );
                }

                return selecetdException.value === "noExceptions";
            });
        }

        if (selectedAlertOptions.length) {
            const alertsMap = {};
            for (const alertOption of selectedAlertOptions) {
                alertsMap[alertOption.value] = true;
            }

            const { material_number, season_code } = data;
            const key = material_number + season_code;
            const materialAlertAndExceptions =
                countryPriceAlertsAndExceptions[key];
            let alertsPresent = false;
            let alertInSelected = false;

            if (materialAlertAndExceptions) {
                for (const { id, entry_type } of materialAlertAndExceptions) {
                    alertsPresent = entry_type === 1;
                    alertInSelected = !!alertsMap[id];
                }
            }
            if (alertsPresent) {
                if (!alertInSelected) return false;
            } else if (!Object.keys(alertsMap).includes("noAlerts"))
                return false;
        }

        return true;
    };

    const checkIfDiff = useCallback(() => {
        if (editMode && !triggerUnsavedEdit?.includes("regional")) {
            if (!triggerUnsavedEdit)
                dispatch(setTriggerUnsavedEdit("regional"));
            else
                dispatch(
                    setTriggerUnsavedEdit(triggerUnsavedEdit + "regional")
                );
        }

        const tempKeys = [];
        let retVal = false;
        const sampleRow = countryLevelPrices[0];
        if (sampleRow) {
            for (let key in countryLevelPrices[0]) {
                if (
                    countryLevelPrices[0][key]?.msrp_generated_value &&
                    !tempKeys.includes(key)
                )
                    tempKeys.push(key);
            }
        }

        for (let row of selectedRows) {
            if (retVal) break;
            for (let key of tempKeys) {
                const origRow = countryLevelPrices.find(
                    (e) => +e.id === +row.data.id
                );
                if (!origRow) continue;
                const orig = origRow[key];

                if (!data[row.data.id]) break;
                const onScreen = data[row.data.id][key];
                if (!onScreen) continue;

                if (
                    isNaN(parseFloat(onScreen.msrp_generated_value)) ||
                    isNaN(parseFloat(onScreen.wholesale_generated_value))
                ) {
                    setChangeDiff(false);

                    return;
                }

                if (
                    +orig.msrp_generated_value !==
                        +onScreen.msrp_generated_value ||
                    +orig.wholesale_generated_value !==
                        +onScreen.wholesale_generated_value
                ) {
                    retVal = true;
                }
            }
        }

        setChangeDiff(retVal);
    }, [selectedRows, countryLevelPrices, data, triggerUnsavedEdit, editMode]);

    const showAuditLog = useCallback((tempDt) => {
        setAuditLogSrc(tempDt);
        setIsAuditLogModalOpen(true);
    }, []);

    const payloadBuilder = useCallback(
        (colKey, isSync) => {
            if (selectedRows.length === 0) {
                dispatch(toastError("Select some rows to proceed"));
                return;
            }

            const payload = [];
            const tempKeys = [];
            if (typeof colKey === "string") tempKeys.push(colKey);
            else if (countryLevelPrices[0]) {
                for (let key in countryLevelPrices[0]) {
                    if (
                        countryLevelPrices[0][key]?.msrp_generated_value &&
                        !tempKeys.includes(key)
                    )
                        tempKeys.push(key);
                }
            }

            const error = { error: false };

            const allowedPriceListsForSync =
                appliedPriceListTableSettings.view_data.columns
                    .filter((e) => e.isSelected)
                    .map((e) => e.name);

            for (let row of selectedRows) {
                const dt = row.data;

                if (
                    [1, 2, 5, 6].includes(dt.approval_status) ||
                    (dt.approval_status > 3 &&
                        !(
                            role_details[SUPER_USER] ||
                            role_details[PRICING_TEAM]
                        ))
                )
                    continue;
                if (error.error) break;

                for (let key of tempKeys) {
                    if (isSync && !allowedPriceListsForSync.includes(key))
                        continue;
                    const orig = dt[key];
                    const copy = data[row.data.id]?.[key];

                    if (
                        orig &&
                        (!isSync || ![1, 2].includes(orig.sync_with_gfe_status))
                    ) {
                        if (!isSync) {
                            if (
                                +orig?.msrp_generated_value ===
                                    +copy?.msrp_generated_value &&
                                +orig?.wholesale_generated_value ===
                                    +copy?.wholesale_generated_value
                            )
                                continue;
                        } else if (
                            isEmpty(dt.msrp_effective_date) ||
                            isEmpty(dt.wholesale_effective_date)
                        ) {
                            dispatch(
                                toastError(
                                    `${
                                        selectedChannels[0].label.includes(
                                            "OUTLET"
                                        )
                                            ? "PFS WAS or Store"
                                            : "MSRP or Wholesale"
                                    } Effective Dates are missing for the material number ${
                                        dt.material_number
                                    } and season code ${dt.season_code}`
                                )
                            );
                            error.error = true;
                        }

                        if (
                            +(isSync ? orig : copy)
                                ?.wholesale_generated_value <= 0
                        ) {
                            dispatch(
                                toastError(
                                    `${orig.price_list_name}'s ${
                                        selectedChannels[0].label.includes(
                                            "OUTLET"
                                        )
                                            ? "Store"
                                            : "wholesale"
                                    } price is not more than 0 for the material number ${
                                        dt.material_number
                                    } and season code ${dt.season_code}`
                                )
                            );
                            error.error = true;
                        }
                        if (
                            +(isSync ? orig : copy)?.msrp_generated_value >
                            9999999999999.99
                        ) {
                            dispatch(
                                toastError(
                                    `${orig.price_list_name}'s ${
                                        selectedChannels[0].label.includes(
                                            "OUTLET"
                                        )
                                            ? "PFS WAS"
                                            : "retail"
                                    } price is not less than 9999999999999.99 for the material number ${
                                        dt.material_number
                                    } and season code ${dt.season_code}`
                                )
                            );
                            error.error = true;
                        }
                        if (
                            isSync
                                ? +orig?.msrp_generated_value <
                                  +orig?.wholesale_generated_value
                                : +copy?.msrp_generated_value <
                                  +copy?.wholesale_generated_value
                        ) {
                            dispatch(
                                toastError(
                                    `${orig.price_list_name}'s ${
                                        selectedChannels[0].label.includes(
                                            "OUTLET"
                                        )
                                            ? "PFS WAS"
                                            : "retail"
                                    } price is less than it's ${
                                        selectedChannels[0].label.includes(
                                            "OUTLET"
                                        )
                                            ? "Store"
                                            : "wholesale"
                                    } price for the material number ${
                                        dt.material_number
                                    } and season code ${dt.season_code}`
                                )
                            );
                            error.error = true;
                        }

                        payload.push(
                            isSync
                                ? orig.price_file_generated_price_id
                                : {
                                      price_list_id: copy.price_list_id,
                                      price_list_name: copy.price_list_name,
                                      price_file_generated_price_id:
                                          copy.price_file_generated_price_id,
                                      price_file_generated_price_uid:
                                          copy.price_file_generated_price_uid,
                                      msrp_generated_value:
                                          +copy?.msrp_generated_value,
                                      wholesale_generated_value:
                                          +copy?.wholesale_generated_value,
                                  }
                        );
                    }
                }
            }

            if (error.error) return error;
            return payload;
        },
        [
            countryLevelPrices,
            selectedRows,
            dispatch,
            data,
            appliedPriceListTableSettings,
        ]
    );

    const onConfirmGFESyncProceed = useCallback(
        (colKey) => {
            const payload = payloadBuilder(colKey, true);
            if (payload.error) return;
            if (isEmpty(payload)) {
                dispatch(
                    toastError(
                        selectedRows.length
                            ? "The selected price lists are either already synced or syncing"
                            : "There is no data to sync"
                    )
                );
                return;
            }

            const countryLevelPriceFetchData = getMaterialFetchData(
                selectedFilters,
                selectedMarkets,
                selectedRegions,
                selectedChannels,
                priceFileId
            );

            countryLevelPriceFetchData.price_file_material_ids =
                countryLevelPrices.map((e) => e.id);

            // dispatch(requestStart());
            dispatch(
                syncToGFE({
                    reqBody: {
                        price_file_material_generated_price_id: payload,
                        channel: selectedChannels[0].label,
                    },
                    countryLevelPriceFetchData,
                    page,
                })
            )
                .then(() => {
                    // dispatch(requestComplete());
                    setEditMode(false);
                    gridRef.current.api.deselectAll();
                })
                .catch((err) => {
                    // dispatch(
                    //     requestFail(
                    //         err?.response?.data?.message || err?.message || err
                    //     )
                    // );
                });

            setConfirmGFESyncModalOpen(false);
            gridRef?.current?.api?.deselectAll();
        },
        [
            countryLevelPrices,
            page,
            selectedRows,
            dispatch,
            payloadBuilder,
            selectedFilters,
        ]
    );

    const onSyncToGFEClick = useCallback(
        (colKey) => {
            if (!selectedRows.length) {
                dispatch(toastError("Please select some materials"));

                return;
            }

            const filteredOnApprovalCol = selectedRows.filter(
                (sm) =>
                    ![1, 2, 5, 6].includes(sm.data.approval_status) ||
                    !(
                        sm.data.approval_status > 3 &&
                        (role_details[SUPER_USER] || role_details[PRICING_TEAM])
                    )
            );
            if (filteredOnApprovalCol.length === 0) {
                dispatch(
                    toastError(
                        "All the selected materials are pending approval or approved"
                    )
                );
                return;
            }

            const filteredSelectedMaterials = filteredOnApprovalCol.filter(
                (material) =>
                    isMaterialWithoutMandatoryException(
                        material.data,
                        countryPriceAlertsAndExceptions
                    )
            );

            if (!filteredSelectedMaterials.length) {
                dispatch(
                    toastError(
                        "All the materials selected have mandatory exception"
                    )
                );

                return;
            }

            const mandatoryExceptionFound =
                filteredSelectedMaterials.length !==
                filteredOnApprovalCol.length;

            if (mandatoryExceptionFound) {
                setConfirmGFEText(
                    `Some materials have mandatory exceptions. Do you want to proceed with the rest of the ${filteredSelectedMaterials.length} material(s) ?`
                );

                setConfirmGFESyncModalOpen(true);
            } else {
                onConfirmGFESyncProceed(colKey);
            }
        },
        [
            selectedRows,
            countryPriceAlertsAndExceptions,
            dispatch,
            onConfirmGFESyncProceed,
        ]
    );

    const onEditClick = () => {
        if (!editMode) {
            const noOfselectedRows = selectedRows.length;
            let noOfValidSelectedRows = 0;

            let newSelectedRows;

            setSelectedRows((prevSelectedRows) => {
                newSelectedRows = prevSelectedRows.filter(
                    (selectedRow) =>
                        !isMaterialBeingSynced(selectedRow.data, originalData)
                );

                noOfValidSelectedRows = newSelectedRows.length;

                return newSelectedRows;
            });

            if (noOfselectedRows !== noOfValidSelectedRows) {
                if (noOfValidSelectedRows === 0) {
                    dispatch(
                        toastError(
                            "All the selected materials' prices are being synced"
                        )
                    );

                    return;
                }

                dispatch(toastError("Some material's prices are being synced"));
            }

            const selectedEditRowsData = cloneDeep(
                newSelectedRows.map((row) => row.data)
            );
            const newEditData = {};
            for (const row of selectedEditRowsData) {
                newEditData[row.id] = row;
            }
            setData(newEditData);

            setEditMode(true);
            dispatch(
                setTriggerUnsavedEdit(
                    !triggerUnsavedEdit
                        ? "regional"
                        : triggerUnsavedEdit.includes("regional")
                        ? triggerUnsavedEdit
                        : triggerUnsavedEdit + "regional"
                )
            );

            return;
        }

        const payload = payloadBuilder();
        if (payload.error) return;
        dispatch(requestStart());

        const countryLevelPriceFetchData = getMaterialFetchData(
            selectedFilters,
            selectedMarkets,
            selectedRegions,
            selectedChannels,
            priceFileId
        );

        countryLevelPriceFetchData.price_file_material_ids =
            countryLevelPrices.map((e) => e.id);

        dispatch(
            saveCountryLevelPrices({
                page,
                reqBody: { price_file_generated_prices: payload },
                materialData: selectedRows,
            })
        )
            .then(() => {
                dispatch(requestComplete());
                setEditMode(false);

                dispatch(
                    setTriggerUnsavedEdit(
                        triggerUnsavedEdit === "regional"
                            ? null
                            : triggerUnsavedEdit?.replace("regional", "")
                    )
                );
                setSelectedRows([]);
                gridRef?.current?.api?.deselectAll();
            })
            .catch((err) => {
                dispatch(
                    requestFail(
                        err?.response?.data?.message || err?.message || err
                    )
                );
                setData({});
            });
    };

    const onEditDiscardClick = () => {
        setEditMode(false);
        dispatch(
            setTriggerUnsavedEdit(
                triggerUnsavedEdit === "regional"
                    ? null
                    : triggerUnsavedEdit?.replace("regional", "")
            )
        );

        setData({});
        setChangeDiff(false);
    };

    const keys = useMemo(() => {
        const errorCols = new Set();

        let tempKeys = [];
        const rowsCountryDt = originalData.map((dd) => dd.country_data);
        for (let row of rowsCountryDt) {
            for (let key in row) {
                if (!tempKeys.map((k) => k.key).includes(key))
                    tempKeys.push({
                        key,
                        id: row[key].price_list_id,
                        is_msrp_cloned: row[key].is_msrp_cloned,
                        msrp_cloned_price_list_name:
                            row[key].msrp_cloned_price_list_name,
                        msrp_cloned_price_list_id:
                            row[key].msrp_cloned_price_list_id,
                        is_wholesale_cloned: row[key].is_wholesale_cloned,
                        wholesale_cloned_price_list_name:
                            row[key].wholesale_cloned_price_list_name,
                        wholesale_cloned_price_list_id:
                            row[key].wholesale_cloned_price_list_id,
                    });
                if (row[key].sync_with_gfe_status === 3) errorCols.add(key);
            }
        }

        const keysMap = {};
        const keyNames = tempKeys
            .map((key) => key.key)
            .sort((a, b) => a.localeCompare(b));

        if (keyNames.includes("EURO STANDARD"))
            keyNames.splice(keyNames.indexOf("EURO STANDARD"), 1);

        for (let key of tempKeys) {
            keysMap[key.key] = key;
        }

        tempKeys = keyNames.map((keyName) => keysMap[keyName]);

        if (showFlag)
            tempKeys = tempKeys.filter((key) => errorCols.has(key.key));

        if (keysMap["EURO STANDARD"]) {
            tempKeys.unshift(keysMap["EURO STANDARD"]);
        }
        tempKeys = tempKeys.filter(Boolean);
        return tempKeys;
    }, [originalData, showFlag]);

    const onLockModalClose = useCallback(() => {
        setIsLockModalOpen(false);
        setLockPayload({});
        setToggleLockConfirmationText("");
    }, []);
    const confirmToggleLock = useCallback((isLocking, type, pl, colType) => {
        setLockPayload({ isLocking, type, pl, colType });

        let text;
        switch (type) {
            case "col":
                let textType = selectedChannels[0].label.includes("OUTLET")
                    ? "PFS WAS"
                    : "MSRP";
                if (colType.msrpOrWhsl !== "msrp") {
                    textType = selectedChannels[0].label.includes("OUTLET")
                        ? "Store"
                        : "WHSL";
                }
                text = `${colType.priceList}'s ${textType}`;
                break;
            case "row":
                text = `material ${pl.material_number} with season code ${pl.season_code}`;
                break;
            case "all":
                text = "entire table";
                break;
            default:
                break;
        }

        let noOfRows = 0;
        gridRef?.current?.api?.forEachNodeAfterFilter(() => noOfRows++);
        if ((type === "col" || type === "all") && noOfRows === 0) {
            dispatch(
                toastError("The operation can't be performed on an empty table")
            );
            setLockPayload({});
            return;
        }
        setToggleLockConfirmationText(
            `Are you sure you want to ${isLocking ? "" : "un"}lock the ${text}?`
        );
        setIsLockModalOpen(true);
    }, []);
    const toggleLock = useCallback(
        (isLocking, type, pl, colType) => {
            let payload = [];

            const allowedPriceListsForLock =
                appliedPriceListTableSettings.view_data.columns
                    .filter((e) => e.isSelected)
                    .map((e) => e.name);

            switch (type) {
                case "atomic":
                    payload = [pl];
                    break;
                case "all":
                    gridRef?.current?.api?.forEachNodeAfterFilter((row) => {
                        keys.forEach((key) => {
                            if (
                                row.data[key.key] &&
                                allowedPriceListsForLock.includes(key.key) &&
                                checkMaterialSelectionValidity(
                                    role_details,
                                    allowed_regions,
                                    allowed_channel_hierarchies,
                                    row.data
                                )
                            ) {
                                payload.push({
                                    [row.data[key.key]
                                        .price_file_generated_price_uid]: {
                                        is_msrp_locked: isLocking,
                                        is_whsl_locked: isLocking,
                                    },
                                });
                            }
                        });
                    });
                    break;
                case "row":
                    Object.values(pl).forEach((val) => {
                        if (
                            val?.price_file_generated_price_uid &&
                            allowedPriceListsForLock.includes(
                                val.price_list_name
                            )
                        ) {
                            payload.push({
                                [val.price_file_generated_price_uid]: {
                                    is_msrp_locked: isLocking,
                                    is_whsl_locked: isLocking,
                                },
                            });
                        }
                    });
                    break;
                case "col":
                    gridRef?.current?.api?.forEachNodeAfterFilter((row) => {
                        if (
                            row.data[colType.priceList] &&
                            checkMaterialSelectionValidity(
                                role_details,
                                allowed_regions,
                                allowed_channel_hierarchies,
                                row.data
                            )
                        )
                            payload.push({
                                [row.data[colType.priceList]
                                    .price_file_generated_price_uid]: {
                                    [`is_${colType.msrpOrWhsl}_locked`]:
                                        isLocking,
                                },
                            });
                    });
                    break;
                default:
                    break;
            }

            if (payload.length === 0) {
                dispatch(
                    toastError(
                        `No allowed material is selected for ${
                            isLocking ? "lock" : "unlock"
                        }`
                    )
                );

                return;
            }

            if (type === "all") {
                setIsLockModalOpen(false);
                setTimeout(() => {
                    dispatch(requestStart());
                }, 0);
            } else {
                dispatch(
                    toastMessage(
                        `${
                            type === "row"
                                ? "Material prices"
                                : type === "col"
                                ? "Price list"
                                : "Material price"
                        } ${isLocking ? "lock" : "unlock"} initiated`
                    )
                );
            }

            dispatch(toggleLockAPI(payload)).then((res) => {
                if (res.error) {
                    onLockModalClose();
                    if (type === "all") {
                        dispatch(
                            requestFail(
                                `Material prices ${
                                    isLocking ? "lock" : "unlock"
                                } failed`
                            )
                        );
                    } else {
                        dispatch(
                            toastError(
                                `${
                                    type === "row"
                                        ? "Material prices"
                                        : type === "col"
                                        ? "Price list"
                                        : "Material price"
                                } ${isLocking ? "lock" : "unlock"} failed`
                            )
                        );
                    }

                    return;
                }

                if (type === "all") {
                    dispatch(requestComplete());
                } else {
                    dispatch(
                        toastSuccessMessage(
                            `${
                                type === "row"
                                    ? "Material prices"
                                    : type === "col"
                                    ? "Price list"
                                    : "Material price"
                            } ${isLocking ? "lock" : "unlock"} success`
                        )
                    );
                }

                const copyData = cloneDeep(originalData);
                const changedUids = [];
                gridRef?.current?.api?.forEachNodeAfterFilter((row) => {
                    if (
                        checkMaterialSelectionValidity(
                            role_details,
                            allowed_regions,
                            allowed_channel_hierarchies,
                            row.data
                        )
                    )
                        changedUids.push(row.data.uid);
                });
                switch (type) {
                    case "all":
                        copyData.forEach((row) => {
                            if (changedUids.includes(row.uid))
                                keys.forEach((key) => {
                                    if (
                                        row.country_data[key.key] &&
                                        allowedPriceListsForLock.includes(
                                            key.key
                                        )
                                    ) {
                                        row.country_data[key.key] = {
                                            ...row.country_data[key.key],
                                            is_msrp_locked: isLocking,
                                            is_whsl_locked: isLocking,
                                        };
                                    }
                                });
                        });
                        break;
                    case "col":
                        copyData.forEach((row) => {
                            if (changedUids.includes(row.uid))
                                if (row.country_data[colType.priceList]) {
                                    row.country_data[colType.priceList] = {
                                        ...row.country_data[colType.priceList],
                                        [`is_${colType.msrpOrWhsl}_locked`]:
                                            isLocking,
                                    };
                                }
                        });
                        break;
                    case "row":
                        const tempRow = copyData.find((r) => r.uid === pl.uid);
                        Object.values(tempRow.country_data).forEach((vl) => {
                            if (
                                vl?.price_file_generated_price_uid &&
                                allowedPriceListsForLock.includes(
                                    vl.price_list_name
                                )
                            ) {
                                vl.is_msrp_locked = isLocking;
                                vl.is_whsl_locked = isLocking;
                            }
                        });
                        break;
                    case "atomic":
                        const row = copyData.find(
                            (r) => r.uid === colType?.data.uid
                        );
                        row.country_data[colType.key][
                            Object.keys(Object.values(pl)[0])[0]
                        ] = isLocking;
                        break;
                    default:
                        break;
                }
                dispatch(setCountryLevelPrices({ page, data: copyData }));
                onLockModalClose();
                gridRef?.current?.api?.deselectAll();
            });
        },
        [
            countryLevelPrices,
            keys,
            dispatch,
            appliedPriceListTableSettings,
            onLockModalClose,
        ]
    );

    const processCellForClipboard = useCallback((params) => {
        const col = params.column.colDef.headerName;
        if (col === "STATUS") {
            return syncStatusCodes[+params.value || 0].text;
        } else if (col === "APPROVAL STATUS")
            return params.value || params.value === 0
                ? APPROVAL_STATUS[params.value]
                : "";
        return params.value;
    }, []);

    const onColumnResize = (event) => {
        if (!event || !event.finished || event.source !== "uiColumnResized") {
            return;
        }

        const regex =
            /.msrp_generated_value$|.wholesale_generated_value$|.sync_with_gfe_status$/;

        if (event.column && regex.test(event.column.colId)) {
            const newUserResizedColumns = cloneDeep(
                userResizedColumns[tableSettingTabIds.PRICE_LIST_SETTINGS]
            );
            const colId = event.column.colId;

            newUserResizedColumns[colId] = event.column.actualWidth;

            dispatch(
                setUserResizedColumns({
                    tabId: tableSettingTabIds.PRICE_LIST_SETTINGS,
                    newData: newUserResizedColumns,
                })
            );
        } else if (
            event.columns.length > 1 &&
            event.columns.reduce(
                (total, col) => (regex.test(col.colId) ? total + 1 : total),
                0
            ) === event.columns.length
        ) {
            const newUserResizedColumns = cloneDeep(
                userResizedColumns[tableSettingTabIds.PRICE_LIST_SETTINGS]
            );

            for (const col of event.columns) {
                const colId = col.colId;

                newUserResizedColumns[colId] = col.actualWidth;
            }

            dispatch(
                setUserResizedColumns({
                    tabId: tableSettingTabIds.PRICE_LIST_SETTINGS,
                    newData: newUserResizedColumns,
                })
            );
        } else if (event.column) {
            const colHeaderName =
                event.column.userProvidedColDef.headerName.toLowerCase();
            const colResized = attributeOptions.find(
                (attribute) => attribute.label.toLowerCase() === colHeaderName
            );

            if (colResized) {
                const colId = colResized.value;
                const newUserResizedColumns = cloneDeep(
                    userResizedColumns[
                        tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS
                    ]
                );

                newUserResizedColumns[colId] = event.column.actualWidth;

                dispatch(
                    setUserResizedColumns({
                        tabId: tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS,
                        newData: newUserResizedColumns,
                    })
                );
            }
        }
    };

    const onDownloadTableDataClick = () => {
        const cols = gridRef.current?.api
            ?.getColumnDefs()
            .filter((e) => e.headerName?.length);
        const withoutChildren = cols
            .filter((e) => !e.children)
            .map((e) => e.field);
        const temp = cols.filter((e) => e.children).map((e) => e.children);
        const withChildren = [];
        temp.forEach((e) => e.forEach((col) => withChildren.push(col.field)));

        const getAlertsAndExceptions = (materialAlertAndExceptions) => {
            let alert = "";
            let exception = "";

            if (materialAlertAndExceptions) {
                for (const {
                    entry_type,
                    description,
                } of materialAlertAndExceptions) {
                    if (entry_type === 1) {
                        alert = `${description}${
                            alert === "" ? "" : ", " + alert
                        }`;
                    } else if (entry_type === 2) {
                        exception = `${description}${
                            exception === "" ? "" : ", " + exception
                        }`;
                    }
                }
            }

            return { alert, exception };
        };

        const processCellCallback = (params) => {
            const { material_number, season_code } = params.node.data;
            const key = material_number + season_code;
            const materialAlertAndExceptions =
                countryPriceAlertsAndExceptions?.[key];

            switch (params.column.colDef.headerName) {
                case "STATUS":
                    return syncStatusCodes[+params.value || 0].text;
                case "APPROVAL STATUS":
                    return params.value || params.value === 0
                        ? APPROVAL_STATUS[params.value]
                        : "";
                case "MARKET":
                    return selectedMarkets[0].label;
                case "REGION":
                    return selectedRegions[0].label;
                case "CHANNEL":
                    return selectedChannels[0].label;
                case "ALERTS":
                    return getAlertsAndExceptions(materialAlertAndExceptions)
                        .alert;
                case "EXCEPTIONS":
                    return getAlertsAndExceptions(materialAlertAndExceptions)
                        .exception;
                default:
                    return params.value ?? "";
            }
        };

        gridRef.current.api.exportDataAsExcel({
            fileName: `Regional Prices ${new Date().toLocaleString()}`,
            processCellCallback,
            columnKeys: [...withoutChildren, ...withChildren],
            onlySelected: selectedRows.length,
        });

        handleClose();
        gridRef?.current?.api?.deselectAll();
    };

    const onTableDataChange = useCallback(
        (event) => {
            const cols = calculateColsToBeAutosized(
                appliedRegionalTableSettings,
                appliedPriceListTableSettings,
                userResizedColumns,
                event
            );

            event.columnApi.autoSizeColumns(cols);

            onTableModelUpdate(event);
        },
        [
            appliedRegionalTableSettings,
            appliedPriceListTableSettings,
            userResizedColumns,
            onTableModelUpdate,
        ]
    );

    useEffect(() => {
        checkIfDiff();
    }, [checkIfDiff]);

    const getKeysForMSRPorWHSL = (type) => {
        const locked = "is_" + (type === "MSRP" ? "msrp" : "whsl") + "_locked";
        const value =
            (type === "MSRP" ? "msrp" : "wholesale") + "_generated_value";
        const clonedPriceList =
            (type === "MSRP" ? "msrp" : "wholesale") +
            "_cloned_price_list_name";
        return { locked, value, clonedPriceList };
    };

    const onEditDataChange = useCallback(
        (editData, cellProps, column, type, e, tempKeys, customKey) => {
            const val = isNaN(parseFloat(e.target.value))
                ? e.target.value
                : e.target.value > 0
                ? parseFloat(parseFloat(e.target.value).toFixed(2))
                : 0;

            const { locked, value, clonedPriceList } =
                getKeysForMSRPorWHSL(type);

            const newObj = { ...editData[cellProps.data.id] };
            if (newObj[column.colId.split(".")[0]])
                newObj[column.colId.split(".")[0]][value] = val;

            tempKeys
                .filter(
                    (e) =>
                        e[clonedPriceList] === customKey.key &&
                        newObj[e.key][locked] !== 1
                )
                .map((e) => e.key)
                .forEach((e) => {
                    if (newObj[e]) newObj[e][value] = val;
                });
            setData({ ...editData, [cellProps.data.id]: newObj });
        },
        []
    );

    const excelStyles = [
        {
            id: "bigNumber",
            numberFormat: {
                format: "0",
            },
        },
    ];

    const onChangeToAMF = () => {
        if (selectedRows.find((node) => node.data.approval_status % 4 !== 2)) {
            dispatch(
                toastError(
                    "Some of the selected materials are not qualified for changing to AMF"
                )
            );

            return;
        }

        const material_ids = selectedRows.map((sr) => sr.data.id);

        dispatch(requestStart());
        changeToAMFFunc({ material_ids })
            .then(() => {
                dispatch(
                    requestComplete({
                        successMessage: "Materials successfully changed to AMF",
                        success: true,
                    })
                );
                const clonedCountryData = cloneDeep(originalData);

                clonedCountryData.forEach((material) => {
                    if (material_ids.includes(material.id))
                        material.approval_status = 4;
                });

                dispatch(
                    setCountryLevelPrices({
                        page,
                        data: clonedCountryData,
                    })
                );

                const clonedBasePricingData = cloneDeep(materials);

                clonedBasePricingData.forEach((material) => {
                    if (material_ids.includes(material.id))
                        material.approval_status = 4;
                });

                dispatch(
                    setMaterials({
                        page,
                        data: clonedBasePricingData,
                    })
                );
                gridRef?.current?.api.deselectAll();
            })
            .catch((err) => {
                dispatch(requestFail());
                dispatch(toastError(err));
            });
    };

    const onSendForApproval = () => {
        if (selectedRows.find((node) => node.data.status !== 2)) {
            dispatch(
                toastError("Some of the selected materials are not synced")
            );

            return;
        }

        for (const { data } of selectedRows) {
            if (
                data.approval_status === 1 ||
                data.approval_status === 2 ||
                data.approval_status === 5 ||
                data.approval_status === 6
            ) {
                dispatch(
                    toastError(
                        "Only unapproved and rejected materials are allowed for approval request"
                    )
                );

                return;
            }

            if (
                data.approval_status > 3 &&
                !(role_details[SUPER_USER] || role_details[PRICING_TEAM])
            ) {
                dispatch(
                    toastError(
                        "Only pricing team and super-user are allowed for AMF approval request"
                    )
                );

                return;
            }
        }

        // if (
        //     selectedRows.find((row) =>
        //         [0, 3].includes(row.data.approval_status)
        //     ) &&
        //     selectedRows.find((row) =>
        //         [4, 7].includes(row.data.approval_status)
        //     )
        // ) {
        //     dispatch(
        //         toastError(
        //             "Selected requests can either be all AMF or all non-AMF"
        //         )
        //     );

        //     return;
        // }

        setShowSendForApproval(true);
    };

    const isColLocked = useMemo(() => {
        // 2 is for all locked
        // 1 is for some locked, some unlocked
        // 0 is for all unlocked

        const filteredUids = [];
        gridRef?.current?.api?.forEachNodeAfterFilter((node) => {
            filteredUids.push(node.data.uid);
        });
        const copyOfOriginalData = filteredUids.length
            ? originalData.filter(
                  (e) =>
                      filteredUids.includes(e.uid) &&
                      checkMaterialSelectionValidity(
                          role_details,
                          allowed_regions,
                          allowed_channel_hierarchies,
                          e
                      )
              )
            : originalData.filter((e) =>
                  checkMaterialSelectionValidity(
                      role_details,
                      allowed_regions,
                      allowed_channel_hierarchies,
                      e
                  )
              );

        const retObj = {};
        keys.forEach((key) => {
            const isAnyMSRPLocked = copyOfOriginalData.find(
                (row) => +row.country_data[key.key]?.is_msrp_locked === 1
            );
            const isAnyMSRPUnlocked = copyOfOriginalData.find(
                (row) => +row.country_data[key.key]?.is_msrp_locked === 0
            );
            const isAnyWHSLLocked = copyOfOriginalData.find(
                (row) => +row.country_data[key.key]?.is_whsl_locked === 1
            );
            const isAnyWHSLUnlocked = copyOfOriginalData.find(
                (row) => +row.country_data[key.key]?.is_whsl_locked === 0
            );
            retObj[key.key] = {
                msrp:
                    isAnyMSRPLocked && isAnyMSRPUnlocked
                        ? 1
                        : isAnyMSRPLocked
                        ? 2
                        : 0,
                whsl:
                    isAnyWHSLLocked && isAnyWHSLUnlocked
                        ? 1
                        : isAnyWHSLLocked
                        ? 2
                        : 0,
            };
        });

        return retObj;
    }, [keys, originalData, filterModel]);

    const colDefs = useMemo(() => {
        const dynamicCols = [];

        const fullPriceColumnNames = [
            "DTC ITP FLAG",
            "WHSL ITP FLAG",
            "EURO Standard WHSL",
            "WHSL PROJECTION Units",
            "WHSL ACTUAL Units",
            "WHSL PROJECTION Sales",
            "WHSL ACTUAL Sales",
            "WHSL PROJECTION Margin",
            "WHSL ACTUAL Margin",
            "WHSL MARGIN %",
        ].map((item) => item.toLowerCase());

        const outletColumnNames = [
            "PARENT MATERIAL NUMBER",
            "PARENT SEASON CODE",
            "PRICE ESTABLISHMENT",
            "TYPE OF PE",
            "DISCOUNT TABLE",
            "DISCOUNT",
        ].map((name) => name.toLowerCase());

        const allowedPriceListsForLock =
            appliedPriceListTableSettings?.view_data?.columns
                ?.filter((e) => e.isSelected)
                .map((e) => e.name);

        if (appliedPriceListTableSettings) {
            const isDiscountColSelected = appliedRegionalTableSettings
                ? appliedRegionalTableSettings.view_data.columns.find(
                      (col) => col.id === "discount"
                  )?.isSelected
                : false;

            for (const column of appliedPriceListTableSettings.view_data
                .columns) {
                const isPriceListSelected = column.isSelected;

                if (!isPriceListSelected) {
                    continue;
                }

                for (let key of keys) {
                    if (column.id !== key.id) {
                        continue;
                    }

                    const currentPriceList = priceLists.find(
                        (priceList) => priceList.name === key.key
                    );

                    dynamicCols.push({
                        field: "",
                        headerName: "",
                        filter: false,
                        sortable: false,
                        valueFormatter: () => "",
                        resizable: false,
                        flex: 0.25,
                        menuTabs: [],
                        cellStyle: { border: "none" },
                        suppressNavigable: true,
                        width: 20,
                    });

                    const temp = {
                        headerName: key.key,
                        suppressMovable: false,
                        headerGroupComponent(props) {
                            return (
                                <div className="align-center">
                                    {allowed_feature_actions?.eu_price_planning
                                        ?.sync_to_gfe ? (
                                        <Tooltip
                                            title="Sync to GFE"
                                            enterDelay={300}
                                        >
                                            <IconButton
                                                sx={{ mr: 0.5 }}
                                                onClick={() =>
                                                    onSyncToGFEClick(key.key)
                                                }
                                                disabled={
                                                    editMode ||
                                                    !allowed_feature_actions
                                                        ?.eu_price_planning
                                                        ?.sync_to_gfe
                                                }
                                            >
                                                <ArrowCircleRightOutlinedIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                    {props.displayName.toUpperCase()}
                                </div>
                            );
                        },
                        headerClass:
                            "ag-grid-column-group-header-centered ag-grid-column-group-header",
                        children: [
                            {
                                headerName: selectedChannels[0].label.includes(
                                    "OUTLET"
                                )
                                    ? "PFS WAS (EUR)"
                                    : `MSRP (${currentPriceList?.msrp_currency})`,
                                field: key.key + ".msrp_generated_value",
                                suppressMovable: true,
                                pinned: getColPinValue(column.pinned),
                                cellStyle:
                                    getColPinValue(column.pinned) === "right"
                                        ? rightPinCellStyle
                                        : undefined,
                                cellRenderer: PriceValueRenderer,
                                width:
                                    userResizedColumns[
                                        tableSettingTabIds.PRICE_LIST_SETTINGS
                                    ][key.key + ".msrp_generated_value"] ??
                                    (appliedPriceListTableSettings.view_data
                                        .columnWidth ===
                                    tableSettingColumnWidthModes.CUSTOM
                                        ? appliedPriceListTableSettings
                                              .view_data.widths[
                                              key.key + ".msrp_generated_value"
                                          ]
                                        : 184),
                                cellRendererParams: {
                                    editMode,
                                    selectedRows,
                                    customKey: key,
                                    keys,
                                    showAuditLog,
                                    type: "MSRP",
                                    editData: data,
                                    setEditData: onEditDataChange,
                                    toggleLock: toggleLock,
                                    valuePrefix:
                                        currentPriceList?.msrp_currency_symbol,
                                },
                                filterParams: numberColFilterParams,
                                valueGetter: (params) =>
                                    params.data[key.key]
                                        ?.msrp_generated_value ?? "-",
                                headerComponent: (headerProps) => {
                                    const lockStatus =
                                        isColLocked[key.key].msrp;
                                    return (
                                        <AgGridHeaderRenderer
                                            {...headerProps}
                                            customComp
                                        >
                                            <IconButton
                                                style={{
                                                    color: "#1d1d1d",
                                                    margin: "0 2px 0 -8px",
                                                    paddingRight:
                                                        lockStatus === 1
                                                            ? "2px"
                                                            : undefined,
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    confirmToggleLock(
                                                        lockStatus === 2
                                                            ? 0
                                                            : 1,
                                                        "col",
                                                        {},
                                                        {
                                                            priceList: key.key,
                                                            msrpOrWhsl: "msrp",
                                                        }
                                                    );
                                                }}
                                                disabled={editMode}
                                            >
                                                {lockStatus === 2 ? (
                                                    <LockOutlinedIcon
                                                        style={{
                                                            fontSize: "initial",
                                                        }}
                                                    />
                                                ) : lockStatus === 1 ? (
                                                    <PartialLocked
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                    />
                                                ) : (
                                                    <Unlocked
                                                        style={{
                                                            height: "17px",
                                                        }}
                                                    />
                                                )}
                                            </IconButton>
                                            <CustomHeader
                                                {...headerProps}
                                                clone={key.is_msrp_cloned}
                                                clonedFrom={
                                                    key.msrp_cloned_price_list_name
                                                }
                                            />
                                        </AgGridHeaderRenderer>
                                    );
                                },
                                headerClass:
                                    "ag-grid-column-group-header-centered ag-grid-header-overflow-visible",
                                cellClass: key.is_msrp_cloned
                                    ? "ag-disabled-cell"
                                    : undefined,
                            },
                            {
                                headerName: selectedChannels[0].label.includes(
                                    "OUTLET"
                                )
                                    ? "STORE PRICE (EUR)"
                                    : `WHSL (${currentPriceList?.wholesale_currency})`,
                                field: key.key + ".wholesale_generated_value",
                                suppressMovable: true,
                                pinned: getColPinValue(column.pinned),
                                cellStyle:
                                    getColPinValue(column.pinned) === "right"
                                        ? rightPinCellStyle
                                        : undefined,
                                width:
                                    userResizedColumns[
                                        tableSettingTabIds.PRICE_LIST_SETTINGS
                                    ][key.key + ".wholesale_generated_value"] ??
                                    (appliedPriceListTableSettings.view_data
                                        .columnWidth ===
                                    tableSettingColumnWidthModes.CUSTOM
                                        ? appliedPriceListTableSettings
                                              .view_data.widths[
                                              key.key +
                                                  ".wholesale_generated_value"
                                          ]
                                        : 184),
                                cellRenderer: PriceValueRenderer,
                                cellRendererParams: {
                                    editMode,
                                    selectedRows,
                                    customKey: key,
                                    keys,
                                    showAuditLog,
                                    type: "WHSL",
                                    editData: data,
                                    setEditData: onEditDataChange,
                                    toggleLock: toggleLock,
                                    valuePrefix:
                                        currentPriceList?.wholesale_currency_symbol,
                                },
                                filterParams: numberColFilterParams,
                                valueGetter: (params) =>
                                    params.data[key.key]
                                        ?.wholesale_generated_value ?? "-",
                                headerComponent: (headerProps) => {
                                    const lockStatus =
                                        isColLocked[key.key].whsl;
                                    return (
                                        <AgGridHeaderRenderer
                                            {...headerProps}
                                            customComp
                                        >
                                            <IconButton
                                                style={{
                                                    color: "#1d1d1d",
                                                    margin: "0 2px 0 -8px",
                                                    paddingRight:
                                                        lockStatus === 1
                                                            ? "2px"
                                                            : undefined,
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    confirmToggleLock(
                                                        lockStatus === 2
                                                            ? 0
                                                            : 1,
                                                        "col",
                                                        {},
                                                        {
                                                            priceList: key.key,
                                                            msrpOrWhsl: "whsl",
                                                        }
                                                    );
                                                }}
                                                disabled={editMode}
                                            >
                                                {lockStatus === 2 ? (
                                                    <LockOutlinedIcon
                                                        style={{
                                                            fontSize: "initial",
                                                        }}
                                                    />
                                                ) : lockStatus === 1 ? (
                                                    <PartialLocked
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                    />
                                                ) : (
                                                    <Unlocked
                                                        style={{
                                                            height: "17px",
                                                        }}
                                                    />
                                                )}
                                            </IconButton>
                                            <CustomHeader
                                                {...headerProps}
                                                clone={
                                                    channelLabel === "OUTLET"
                                                        ? null
                                                        : key.is_wholesale_cloned
                                                }
                                                clonedFrom={
                                                    channelLabel === "OUTLET"
                                                        ? null
                                                        : key.wholesale_cloned_price_list_name
                                                }
                                            />
                                        </AgGridHeaderRenderer>
                                    );
                                },
                                headerClass:
                                    "ag-grid-column-group-header-centered ag-grid-header-overflow-visible",
                                cellClass: key.is_wholesale_cloned
                                    ? "ag-disabled-cell"
                                    : undefined,
                            },
                            {
                                headerName: "STATUS",
                                field: key.key + ".sync_with_gfe_status",
                                suppressMovable: true,
                                pinned: getColPinValue(column.pinned),
                                cellStyle:
                                    getColPinValue(column.pinned) === "right"
                                        ? rightPinCellStyle
                                        : undefined,
                                width:
                                    userResizedColumns[
                                        tableSettingTabIds.PRICE_LIST_SETTINGS
                                    ][key.key + ".sync_with_gfe_status"] ??
                                    (appliedPriceListTableSettings.view_data
                                        .columnWidth ===
                                    tableSettingColumnWidthModes.CUSTOM
                                        ? appliedPriceListTableSettings
                                              .view_data.widths[
                                              key.key + ".sync_with_gfe_status"
                                          ]
                                        : 170),
                                valueGetter: (params) =>
                                    params.data[key.key]
                                        ?.sync_with_gfe_status ?? "-",
                                cellRenderer({ value, node }) {
                                    const vl = value ?? "-";
                                    if (vl === "-") return "-";
                                    return (
                                        <Button
                                            variant="text"
                                            onClick={() =>
                                                showAuditLog({
                                                    data: node.data,
                                                    value: vl,
                                                    price_list_name: key.key,
                                                    type: "status",
                                                    logType: "price",
                                                })
                                            }
                                        >
                                            <span
                                                className={styles["status-dot"]}
                                                style={{
                                                    backgroundColor:
                                                        syncStatusCodes[vl]
                                                            .backgroundColor,
                                                }}
                                            />{" "}
                                            {syncStatusCodes[vl].text}
                                        </Button>
                                    );
                                },
                                filterValueGetter({ data: dt, colDef }) {
                                    const cols = colDef.field.split(".");
                                    const val = cols.reduce(
                                        (a, b) => a?.[b],
                                        dt
                                    );
                                    if (val === null || val === undefined)
                                        return "";
                                    return (
                                        syncStatusCodes[val]?.text ||
                                        "Not Synced"
                                    );
                                },
                            },
                        ].slice(
                            0,
                            appliedRegionalTableSettings.view_data.columns.find(
                                (item) => item.id === "status"
                            )?.isSelected
                                ? 3
                                : 2
                        ),
                    };

                    if (
                        selectedChannels[0].label.includes("OUTLET") &&
                        isDiscountColSelected
                    )
                        temp.children.splice(1, 0, {
                            headerName: "DISCOUNT",
                            field: key.key + ".discount",
                            suppressMovable: true,
                            pinned: getColPinValue(column.pinned),
                            cellStyle:
                                getColPinValue(column.pinned) === "right"
                                    ? rightPinCellStyle
                                    : undefined,
                            valueGetter({ data }) {
                                return data[key.key].msrp_generated_value
                                    ? (
                                          ((data[key.key].msrp_generated_value -
                                              data[key.key]
                                                  .wholesale_generated_value) /
                                              data[key.key]
                                                  .msrp_generated_value) *
                                          100
                                      ).toFixed(2) + "%"
                                    : "0.00%";
                            },
                            comparator: (a, b) =>
                                Number(a.substring(0, a.length - 1)) >
                                Number(b.substring(0, b.length - 1))
                                    ? 1
                                    : -1,
                            width:
                                userResizedColumns[
                                    tableSettingTabIds.PRICE_LIST_SETTINGS
                                ][key.key + ".discount"] ??
                                (appliedPriceListTableSettings.view_data
                                    .columnWidth ===
                                tableSettingColumnWidthModes.CUSTOM
                                    ? appliedPriceListTableSettings.view_data
                                          .widths[key.key + ".discount"]
                                    : 135),
                        });

                    dynamicCols.push(temp);
                }
            }
        }

        const clonedCountryLevelPriceCols = cloneDeep(countryLevelPriceCols);
        clonedCountryLevelPriceCols[1].cellRendererParams.page = page;
        clonedCountryLevelPriceCols[1].cellRendererParams.toggleLock =
            confirmToggleLock;
        clonedCountryLevelPriceCols[1].cellRendererParams.editMode = editMode;
        clonedCountryLevelPriceCols[1].cellRendererParams.appliedPriceListTableSettings =
            appliedPriceListTableSettings;
        clonedCountryLevelPriceCols[1].headerComponent = (headerProps) => {
            const isAnyLocked = Object.entries(isColLocked).find(
                (val) =>
                    allowedPriceListsForLock?.includes(val[0]) &&
                    (+val[1].msrp > 0 || +val[1].whsl > 0)
            );
            const isAnyUnlocked = Object.entries(isColLocked).find(
                (val) =>
                    allowedPriceListsForLock?.includes(val[0]) &&
                    (+val[1].msrp < 2 || +val[1].whsl < 2)
            );
            const lockStatus =
                isAnyLocked && isAnyUnlocked ? 1 : isAnyLocked ? 2 : 0;

            return (
                <AgGridHeaderRenderer {...headerProps} customComp>
                    <IconButton
                        style={{
                            color: "#1d1d1d",
                            margin: "0 2px 0 -8px",
                            paddingRight: lockStatus === 1 ? "2px" : undefined,
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            confirmToggleLock(lockStatus === 2 ? 0 : 1, "all");
                        }}
                        disabled={editMode}
                    >
                        {lockStatus === 2 ? (
                            <LockOutlinedIcon style={{ fontSize: "initial" }} />
                        ) : lockStatus === 1 ? (
                            <PartialLocked
                                style={{ height: "20px", width: "20px" }}
                            />
                        ) : (
                            <Unlocked style={{ height: "17px" }} />
                        )}
                    </IconButton>
                    {headerProps.displayName.toUpperCase()}
                </AgGridHeaderRenderer>
            );
        };

        const pinnedColMap = {};

        const selectedAttributeNames = appliedRegionalTableSettings
            ? appliedRegionalTableSettings.view_data.columns
                  .filter((col) => {
                      pinnedColMap[col.id] = col.pinned;

                      if (
                          !selectedChannels[0].label.includes("OUTLET") &&
                          outletColumnNames.includes(col.name.toLowerCase())
                      )
                          return false;

                      if (
                          !selectedChannels[0].label.includes("FULL-PRICE") &&
                          fullPriceColumnNames.includes(col.name.toLowerCase())
                      )
                          return false;

                      return col.isSelected;
                  })
                  .map((sa) => sa.id)
            : [];
        const selectedAttributeOptions = [];

        for (const attrName of selectedAttributeNames) {
            for (const attrOption of attributeOptions) {
                if (attrName === attrOption.value) {
                    selectedAttributeOptions.push(attrOption);
                }
            }
        }

        const attributeCols = selectedAttributeOptions.map((ao) => {
            let columnName = ao.label;
            if (
                ao.label.toLowerCase().replace("(eur)", "").trim() in
                outletNameReplacementMapping
            ) {
                columnName =
                    outletNameReplacementMapping[
                        ao.label.toLowerCase().replace("(eur)", "").trim()
                    ];
            }
            return {
                headerName: columnName.toUpperCase(),
                field: ao.value,
                cellRenderer: !ao.showByDefault
                    ? ({ value }) => {
                          return (
                              <>
                                  <Tooltip title={value}>
                                      <span>{value}</span>
                                  </Tooltip>
                              </>
                          );
                      }
                    : ao.value.includes("effective_date")
                    ? ({ value, node }) => {
                          //   if (!value) return "-";
                          return (
                              <Button
                                  variant="text"
                                  onClick={() =>
                                      showAuditLog({
                                          data: node.data,
                                          value: value,
                                          type:
                                              ao.value ===
                                              "wholesale_effective_date"
                                                  ? "WHSL"
                                                  : "MSRP",
                                          logType: "effective_date",
                                      })
                                  }
                              >
                                  {value === null ||
                                  value === undefined ||
                                  isNaN(parseFloat(value))
                                      ? "-"
                                      : value}
                              </Button>
                          );
                      }
                    : undefined,
                headerClass: "ag-grid-column-group-header-centered",
                ...ao,
                width:
                    userResizedColumns[
                        tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS
                    ][ao.value] ??
                    (appliedRegionalTableSettings.view_data.columnWidth ===
                    tableSettingColumnWidthModes.CUSTOM
                        ? appliedRegionalTableSettings.view_data.widths[
                              ao.value
                          ]
                        : undefined) ??
                    ao.width,
                pinned: getColPinValue(pinnedColMap[ao.value]),
                cellStyle:
                    getColPinValue(pinnedColMap[ao.value]) === "right"
                        ? rightPinCellStyle
                        : undefined,
            };
        });

        return [
            {
                ...clonedCountryLevelPriceCols[0],
                checkboxSelection: () =>
                    (allowed_feature_actions?.eu_price_planning?.sync_to_gfe ||
                        allowed_feature_actions?.eu_price_planning?.edit) &&
                    !editMode,
                headerCheckboxSelection: () =>
                    (allowed_feature_actions?.eu_price_planning?.sync_to_gfe ||
                        allowed_feature_actions?.eu_price_planning?.edit) &&
                    !editMode &&
                    countryLevelPrices.filter(isMaterialSelectable).length,
                headerCheckboxSelectionFilteredOnly: true,
            },
            { headerName: "MARKET", hide: true, field: "market" },
            { headerName: "REGION", hide: true, field: "region" },
            { headerName: "CHANNEL", hide: true, field: "channel" },
            { headerName: "ALERTS", hide: true, field: "alerts" },
            { headerName: "EXCEPTIONS", hide: true, field: "exceptions" },
            {
                headerName: "LOCK STATUS",
                hide: true,
                field: "lock_status",
                valueGetter: ({ data }) => {
                    const statusNumber = getMaterialLockStatus(
                        appliedPriceListTableSettings,
                        data
                    );

                    switch (statusNumber) {
                        case 1:
                            return "Partially Locked";
                        case 2:
                            return "Locked";
                        default:
                            return "Unlocked";
                    }
                },
            },
            ...clonedCountryLevelPriceCols.slice(1),
            ...attributeCols,
            ...dynamicCols,
        ];
    }, [
        data,
        appliedRegionalTableSettings,
        appliedPriceListTableSettings,
        userResizedColumns,
        priceLists,
        countryLevelPrices,
        editMode,
        page,
        keys,
        isColLocked,
        toggleLock,
        confirmToggleLock,
        allowed_feature_actions,
        selectedRows,
        onSyncToGFEClick,
        isMaterialSelectable,
        // originalData,
        onEditDataChange,
        showAuditLog,
    ]);

    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            showDisabledCheckboxes:
                (allowed_feature_actions?.eu_price_planning?.sync_to_gfe ||
                    allowed_feature_actions?.eu_price_planning?.edit) &&
                !editMode,
            valueFormatter: fallbackFormatter,
            cellStyle: {
                display: "flex",
                alignItems: "center",
            },
            filter: "agMultiColumnFilter",
            floatingFilter: true,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            resizable: true,
            valueGetter: (params) => params.data[params.colDef.field] ?? "-",
        };
    }, [allowed_feature_actions, editMode]);

    const rowHeight = useMemo(() => {
        return appliedRegionalTableSettings?.view_data.columns
            .filter((col) => col.isSelected)
            .map((sta) => sta.id)
            .includes("image_url")
            ? "55"
            : "45";
    }, [appliedRegionalTableSettings]);

    const onConfirmGFESyncModalClose = () => {
        setConfirmGFESyncModalOpen(false);
    };

    const onConfirmGFESyncCancel = () => {
        setConfirmGFESyncModalOpen(false);
    };

    const onTableSettingsApply = (tabId, view) => {
        if (tabId === tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS) {
            setAppliedRegionalTableSettings(view);
        } else if (tabId === tableSettingTabIds.PRICE_LIST_SETTINGS) {
            setAppliedPriceListTableSettings(view);
        }

        dispatch(
            setUserResizedColumns({
                tabId: tabId,
                newData: {},
            })
        );

        setShowTableSettings(false);
    };

    const onTableSettingsClose = () => {
        setShowTableSettings(false);
    };

    useEffect(() => {
        gridRef.current?.api?.resetRowHeights();
    }, [appliedRegionalTableSettings, appliedPriceListTableSettings]);

    const isRowSelectable = useCallback(
        ({ data }) => {
            return isMaterialSelectable(data);
        },
        [isMaterialSelectable]
    );

    const onRefreshClick = () => {
        const materialFetchData = getMaterialFetchData(
            selectedFilters,
            selectedMarkets,
            selectedRegions,
            selectedChannels,
            priceFileId
        );

        dispatch(
            getPriceFileMaterialsAndCountryLevelPrices(page, materialFetchData)
        );
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSettingsClick = () => {
        setShowTableSettings(true);
    };

    const onDownloadAuditLogClick = useCallback(() => {
        const mainFilters = {
            selectedMarkets,
            selectedRegions,
            selectedChannels,
        };
        auditLogDownloader(
            downloadAuditLogData,
            selectedRows,
            countryLevelPrices,
            mainFilters,
            gridRef,
            handleClose,
            dispatch
        );
    }, [selectedRows, countryLevelPrices, dispatch]);

    const onSelectionChanged = useCallback(
        (val) => {
            setSelectedRows(val.api.getSelectedNodes());
            onTableSelectChange(val);
        },
        [onTableSelectChange]
    );

    useEffect(() => {
        if (gridRef?.current?.columnApi && currentTab === "country") {
            const cols = calculateColsToBeAutosized(
                appliedRegionalTableSettings,
                appliedPriceListTableSettings,
                userResizedColumns,
                gridRef.current
            );

            gridRef.current.columnApi.autoSizeColumns(cols);
        }
    }, [
        currentTab,
        appliedPriceListTableSettings,
        appliedRegionalTableSettings,
        userResizedColumns,
    ]);

    const onSendForApprovalCloseClick = () => {
        setShowSendForApproval(false);
    };

    const onSnapshotNameChange = (e) => {
        const newSnapshotName = e.target.value;

        setSnapshotName(newSnapshotName);
    };

    const onSelectedApproverMailIDOptionsChange = (data) => {
        if (
            selectedCCMailIDOptions
                .map((cc) => cc.value)
                .includes(data.selectedItems[0].value)
        ) {
            setSelectedCCMailIDOptions(
                selectedCCMailIDOptions.filter(
                    (cc) => cc.value !== data.selectedItems[0].value
                )
            );
        }
        setSelectedApproverMailIDOptions(data.selectedItems);
    };

    const onSelectedCCMailIDOptionsChange = (data) => {
        setSelectedCCMailIDOptions(data.selectedItems);
    };

    const onApprovalReqDescChange = (e) => {
        const newApprovalReqDesc = e.target.value;

        setApprovalReqDesc(newApprovalReqDesc);
    };

    const onApprovalReqTypeChange = (e) => {
        const newApprovalReqType = e.target.value;

        setApprovalReqType(newApprovalReqType);
    };

    const onSendForApprovalSendClick = () => {
        if (areSendForApprovalInputsInvalid) {
            return;
        }

        if (!/^[a-zA-Z0-9-_ ]+$/.test(snapshotNameTrimmed)) {
            dispatch(
                toastError(
                    "Snapshot name may only contain alphabets, numbers, hyphens, underscores and spaces"
                )
            );
            return;
        }

        if (snapshotNameTrimmed.length > 100) {
            dispatch(
                toastError("Snapshot name's length can't be more than 100")
            );
            return;
        }

        const systemViewForAttribute = columnSettings[
            tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS
        ].find((item) => item.view_id === -1);

        const systemViewForPriceList = columnSettings[
            tableSettingTabIds.PRICE_LIST_SETTINGS
        ].find((item) => item.view_id === -1);

        const [selectedColumnsForAttribute, selectedColumnsForPriceList] = [
            systemViewForAttribute,
            systemViewForPriceList,
        ].map((list) =>
            list.view_data.columns
                .filter((item) => item.isSelected)
                .map((item) => item.name)
        );

        const newApprovalStatus =
            selectedRows[0].data.approval_status ===
                approvalStatus.UNAPPROVED.value ||
            selectedRows[0].data.approval_status ===
                approvalStatus.REJECTED.value
                ? approvalStatus.PENDING_APPROVAL.value
                : approvalStatus.AMF_PENDING_APPROVAL.value;

        const unique_merch_division = new Set();
        const material_ids = selectedRows.map((sr) => {
            if (sr.data.product_h1_name)
                unique_merch_division.add(sr.data.product_h1_name);
            return sr.data.id;
        });

        const payload = {
            approver_email: selectedApproverMailIDOptions[0].value,
            email_cc_user_id: selectedCCMailIDOptions.map((obj) => obj.value),
            email_schedule_flag: parseInt(approvalReqType),
            email_schedule_time:
                approvalReqType === approvalReqTypes.SCHEDULED_TIME
                    ? getNextScheduledTime()
                    : getCurrentTimeForSchedule(),
            approved_status: newApprovalStatus,
            unique_merch_division: [...unique_merch_division],
            material_ids,
            market_id: selectedMarkets[0].value,
            region: selectedRegions[0].label,
            channel: selectedChannels[0].label,
            snapshot_name: snapshotNameTrimmed,
            description: approvalReqDescTrimmed,
            attributes_settings: filterAndMapColumnChannelWise(
                selectedColumnsForAttribute.map((item) => ({
                    name: item,
                    id: item,
                })),
                selectedChannels
            ).map((item) => item.name),
            price_list_settings: selectedColumnsForPriceList,
        };

        dispatch(createApprovalExcel(payload))
            .then((request_id) => {
                const payload = { id: request_id };

                dispatch(approvalMailRequest(payload))
                    .then(() => {
                        const clonedCountryData = cloneDeep(originalData);

                        clonedCountryData.forEach((material) => {
                            if (material_ids.includes(material.id))
                                material.approval_status = newApprovalStatus;
                        });

                        dispatch(
                            setCountryLevelPrices({
                                page,
                                data: clonedCountryData,
                            })
                        );

                        const clonedBasePricingData = cloneDeep(materials);

                        clonedBasePricingData.forEach((material) => {
                            if (material_ids.includes(material.id))
                                material.approval_status = newApprovalStatus;
                        });

                        dispatch(
                            setMaterials({
                                page,
                                data: clonedBasePricingData,
                            })
                        );

                        gridRef?.current?.api.deselectAll();
                    })
                    .catch(() => {});
            })
            .catch(() => {});

        setShowSendForApproval(false);
    };

    const onSendForApprovalCancelClick = () => {
        setShowSendForApproval(false);
    };

    useEffect(() => {
        updateClearAllFilterStatus();
    }, [
        showFlag,
        selectedLockStatusOptions,
        selectedExceptionOptions,
        selectedAlertOptions.length,
    ]);
    const onGridReady = () => {
        gridRef.current.api.addEventListener("filterChanged", (event) => {
            updateClearAllFilterStatus();
        });
    };
    const updateClearAllFilterStatus = () => {
        setIsAnyFilterApplied(
            gridRef?.current?.api?.isAnyFilterPresent() +
                showFlag +
                selectedLockStatusOptions.length +
                selectedExceptionOptions.length +
                selectedAlertOptions.length
        );
    };

    const onMaterialBulkEditClick = () => {
        if (!allowed_feature_actions?.eu_price_planning?.edit) {
            return;
        }

        setBulkEditRtlPriceLists([]);
        setBulkEditWhslPriceLists([]);
        setBulkEditRtlValue(1);
        setBulkEditWhslValue(1);

        setBulkEditModalOpen(true);
    };

    const onBulkEditModalCancel = () => {
        setBulkEditModalOpen(false);
    };

    const payloadAdder = (priceLists, sr, isConfirmed, lockField, valField) => {
        const retVal = [];

        for (const pl of priceLists) {
            const obj = sr.data[pl];
            if (!obj)
                return [
                    "Prices are not generated for LATAM countries. Please generate the prices before continuing with the edits.",
                ];
            if (
                (obj[lockField] || obj.sync_with_gfe_status === 1) &&
                !isConfirmed
            )
                return [true];
            if (obj.sync_with_gfe_status === 1) continue;
            const rtlVal =
                valField === "rtl" && !obj[lockField]
                    ? bulkEditRtlValue
                    : obj.msrp_generated_value;
            const whslVal =
                valField !== "rtl" && !obj[lockField]
                    ? bulkEditWhslValue
                    : obj.wholesale_generated_value;
            retVal.push({
                price_list_id: obj.price_list_id,
                price_list_name: obj.price_list_name,
                is_msrp_locked: obj.is_msrp_locked,
                is_whsl_locked: obj.is_whsl_locked,
                msrp_cloned_price_list_id: obj.msrp_cloned_price_list_id,
                wholesale_cloned_price_list_id:
                    obj.wholesale_cloned_price_list_id,
                id: sr.data.id,
                price_file_generated_price_id:
                    obj.price_file_generated_price_id,
                price_file_generated_price_uid:
                    obj.price_file_generated_price_uid,
                msrp_generated_value: rtlVal,
                wholesale_generated_value: whslVal,
            });
        }

        return retVal;
    };

    const bothPayloadAdder = (bothRtlWhslPriceLists, sr, isConfirmed) => {
        const payload = [];

        for (let bpl of bothRtlWhslPriceLists) {
            const obj = sr.data[bpl];
            if (!obj)
                return [
                    "Prices are not generated for LATAM countries. Please generate the prices before continuing with the edits.",
                ];
            if (
                (obj.is_msrp_locked ||
                    obj.is_whsl_locked ||
                    obj.sync_with_gfe_status === 1) &&
                !isConfirmed
            )
                return [true];
            if (obj.sync_with_gfe_status === 1) continue;
            const rtlVal = obj.is_msrp_locked
                ? obj.msrp_generated_value
                : bulkEditRtlValue;
            const whslVal = obj.is_whsl_locked
                ? obj.wholesale_generated_value
                : bulkEditWhslValue;
            payload.push({
                price_list_id: obj.price_list_id,
                is_msrp_locked: obj.is_msrp_locked,
                is_whsl_locked: obj.is_whsl_locked,
                msrp_cloned_price_list_id: obj.msrp_cloned_price_list_id,
                wholesale_cloned_price_list_id:
                    obj.wholesale_cloned_price_list_id,
                id: sr.data.id,
                price_list_name: obj.price_list_name,
                price_file_generated_price_id:
                    obj.price_file_generated_price_id,
                price_file_generated_price_uid:
                    obj.price_file_generated_price_uid,
                msrp_generated_value: rtlVal,
                wholesale_generated_value: whslVal,
            });
        }

        return payload;
    };

    const priceListsBreakdownForBulkEdit = () => {
        const onlyRtlPriceLists = new Set();
        bulkEditRtlPriceLists.forEach((rpl) => {
            onlyRtlPriceLists.add(rpl.value);
            keys.forEach((temp) => {
                if (temp.msrp_cloned_price_list_id === rpl.id) {
                    onlyRtlPriceLists.add(temp.key);
                }
            });
        });
        const onlyWhslPriceLists = new Set();
        bulkEditWhslPriceLists.forEach((wpl) => {
            onlyWhslPriceLists.add(wpl.value);
            keys.forEach((temp) => {
                if (temp.wholesale_cloned_price_list_id === wpl.id) {
                    onlyWhslPriceLists.add(temp.key);
                }
            });
        });
        const bothRtlWhslPriceLists = new Set();
        const tempOnlyRtl = cloneDeep(onlyRtlPriceLists);
        for (let rpl of tempOnlyRtl) {
            if (onlyWhslPriceLists.has(rpl)) {
                bothRtlWhslPriceLists.add(rpl);
                onlyRtlPriceLists.delete(rpl);
                onlyWhslPriceLists.delete(rpl);
            }
        }

        return { onlyRtlPriceLists, onlyWhslPriceLists, bothRtlWhslPriceLists };
    };

    const onBulkEditSubmit = (isConfirmed) => {
        const payload = [];
        let skip = false;

        const { onlyRtlPriceLists, onlyWhslPriceLists, bothRtlWhslPriceLists } =
            priceListsBreakdownForBulkEdit();

        for (let sr of selectedRows) {
            if (skip) break;

            const rtlPayload = payloadAdder(
                onlyRtlPriceLists,
                sr,
                isConfirmed,
                "is_msrp_locked",
                "rtl"
            );
            if (rtlPayload[0] === true) {
                skip = true;
                break;
            }
            payload.push(...rtlPayload);

            const whslPayload = payloadAdder(
                onlyWhslPriceLists,
                sr,
                isConfirmed,
                "is_whsl_locked",
                "whsl"
            );
            if (whslPayload[0] === true) {
                skip = true;
                break;
            }
            payload.push(...whslPayload);

            const bothPayload = bothPayloadAdder(
                bothRtlWhslPriceLists,
                sr,
                isConfirmed
            );
            if (bothPayload[0] === true) {
                skip = true;
                break;
            }
            payload.push(...bothPayload);
        }

        const firstError = payload.find((e) => typeof e === "string");
        if (firstError) {
            dispatch(toastError(firstError));
            return;
        }

        if (skip) {
            setBulkEditModalOpen(false);
            setBulkEditConfirmModalOpen(true);
        } else {
            setBulkEditModalOpen(false);
            setBulkEditConfirmModalOpen(false);
            dispatch(requestStart());

            dispatch(
                saveCountryLevelPrices({
                    page,
                    reqBody: {
                        price_file_generated_prices: payload,
                    },
                    materialData: selectedRows,
                })
            )
                .then(() => {
                    dispatch(requestComplete());
                    if (isContinueEditing) {
                        const selectedIds = selectedRows.map((e) => e.data.id);

                        const newRows = [];
                        gridRef.current?.api?.forEachNode((node) => {
                            if (selectedIds.includes(node.data.id)) {
                                node.setSelected(true);
                                newRows.push(node);
                            }
                        });
                        setSelectedRows(newRows);
                        onMaterialBulkEditClick();
                        isContinueEditing = false;
                    } else {
                        gridRef.current.api.deselectAll();
                        setSelectedRows([]);
                    }
                })
                .catch((err) => {
                    dispatch(
                        requestFail(
                            err?.response?.data?.message || err?.message || err
                        )
                    );
                });
        }
    };

    const bulkEditConfirmationCancel = () => {
        setBulkEditConfirmModalOpen(false);
        isContinueEditing = false;
        setBulkEditModalOpen(true);
    };

    return (
        <div style={{ position: "relative" }}>
            <LoadingOverlay
                showLoader={
                    regionalPricingLoader !== 0 || basePricingLoader !== 0
                }
                isLocalLoader
            />
            <div
                className="align-center justify-space-between"
                style={{ columnGap: "12px" }}
            >
                <div
                    className="align-center"
                    style={{
                        maxWidth: "calc(100% - 644px)",
                        marginRight: "12px",
                    }}
                >
                    {/* <h4 style={{ marginTop: "0", marginBottom: "0" }}>
                        Country Level Price
                    </h4> */}
                    {selectedPlanGroup ? (
                        <div
                            style={{
                                minWidth: 0,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <span>Selected plan group : </span>
                            <Tooltip title={selectedPlanGroup.name}>
                                <strong>{selectedPlanGroup.name}</strong>
                            </Tooltip>
                        </div>
                    ) : null}
                </div>
                <div
                    className="align-center flex-wrap"
                    style={{ justifyContent: "flex-end", gap: "20px" }}
                >
                    {editMode ? (
                        <>
                            <Tooltip title="Save Changes" enterDelay={300}>
                                <Button
                                    variant="contained"
                                    onClick={onEditClick}
                                    sx={{ mr: 0 }}
                                    disabled={!changeDiff}
                                >
                                    <SaveOutlinedIcon fontSize="small" />
                                </Button>
                            </Tooltip>
                            <Button
                                variant="outlined"
                                onClick={onEditDiscardClick}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <>
                            {selectedRows.length ? (
                                <>
                                    {role_details[SUPER_USER] ||
                                    role_details[PRICING_TEAM] ? (
                                        <Button
                                            variant="contained"
                                            onClick={onChangeToAMF}
                                        >
                                            Change to AMF
                                        </Button>
                                    ) : null}
                                    {role_details[SUPER_USER] ||
                                    role_details[PRICING_TEAM] ? (
                                        <Button
                                            variant="contained"
                                            onClick={onSendForApproval}
                                        >
                                            Send for Approval
                                        </Button>
                                    ) : null}
                                    {allowed_feature_actions?.eu_price_planning
                                        ?.edit && selectedRows.length > 1 ? (
                                        <Tooltip title="Bulk edit materials">
                                            <Button
                                                variant="contained"
                                                disabled={
                                                    selectedRows.length === 1 ||
                                                    !allowed_feature_actions
                                                        ?.eu_price_planning
                                                        ?.edit ||
                                                    regionalPricingAlertsConfigLoader !==
                                                        0 ||
                                                    selectedRows.find(
                                                        (sr) =>
                                                            sr.data
                                                                .approval_status %
                                                                4 ===
                                                            2
                                                    )
                                                }
                                                onClick={
                                                    onMaterialBulkEditClick
                                                }
                                            >
                                                <EditNoteOutlinedIcon fontSize="small" />
                                            </Button>
                                        </Tooltip>
                                    ) : null}
                                    {allowed_feature_actions?.eu_price_planning
                                        ?.sync_to_gfe && !editMode ? (
                                        <>
                                            <Tooltip
                                                title="Sync to GFE"
                                                enterDelay={300}
                                            >
                                                <Button
                                                    variant="contained"
                                                    onClick={onSyncToGFEClick}
                                                    disabled={
                                                        editMode ||
                                                        !allowed_feature_actions
                                                            ?.eu_price_planning
                                                            ?.sync_to_gfe ||
                                                        regionalPricingAlertsConfigLoader !==
                                                            0
                                                    }
                                                >
                                                    <ArrowCircleRightOutlinedIcon fontSize="small" />
                                                </Button>
                                            </Tooltip>
                                            <ClearAllFilterButton
                                                isVisible={isAnyFilterApplied}
                                                agGridApi={
                                                    gridRef?.current?.api
                                                }
                                                onClick={() => {
                                                    setShowFlag(false);
                                                    setSelectedLockStatusOptions(
                                                        []
                                                    );
                                                    setSelectedExceptionOptions(
                                                        []
                                                    );
                                                    setSelectedAlertOptions([]);
                                                }}
                                            />
                                        </>
                                    ) : null}
                                    {allowed_feature_actions?.eu_price_planning
                                        ?.edit ? (
                                        <Tooltip
                                            title="Enter Edit Mode"
                                            enterDelay={300}
                                        >
                                            <Button
                                                variant="contained"
                                                onClick={onEditClick}
                                                sx={{ mr: 0 }}
                                                disabled={
                                                    !allowed_feature_actions
                                                        ?.eu_price_planning
                                                        ?.edit ||
                                                    regionalPricingAlertsConfigLoader !==
                                                        0 ||
                                                    selectedRows.length > 1 ||
                                                    selectedRows[0].data
                                                        .approval_status %
                                                        4 ===
                                                        2 ||
                                                    !Object.values(
                                                        selectedRows[0].data
                                                    ).find((pl) => {
                                                        return (
                                                            pl &&
                                                            ((pl.is_msrp_locked !==
                                                                undefined &&
                                                                !pl.is_msrp_locked) ||
                                                                (pl.is_whsl_locked !==
                                                                    undefined &&
                                                                    !pl.is_whsl_locked))
                                                        );
                                                    })
                                                }
                                            >
                                                <EditOutlinedIcon fontSize="small" />
                                            </Button>
                                        </Tooltip>
                                    ) : null}
                                    {allowed_feature_actions?.eu_price_planning
                                        ?.download ? (
                                        <Tooltip title="Download Materials">
                                            <Button
                                                variant="contained"
                                                onClick={handleClick}
                                                sx={{ mr: 0 }}
                                                disabled={
                                                    !allowed_feature_actions
                                                        ?.eu_price_planning
                                                        ?.download
                                                }
                                            >
                                                <DownloadOutlinedIcon fontSize="small" />
                                            </Button>
                                        </Tooltip>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    {selectedRows.length ? (
                                        <>
                                            {role_details[SUPER_USER] ||
                                            role_details[PRICING_TEAM] ? (
                                                <Button
                                                    variant="contained"
                                                    onClick={onChangeToAMF}
                                                >
                                                    Change to AMF
                                                </Button>
                                            ) : null}
                                            {role_details[SUPER_USER] ||
                                            role_details[PRICING_TEAM] ? (
                                                <Button
                                                    variant="contained"
                                                    onClick={onSendForApproval}
                                                >
                                                    Send for Approval
                                                </Button>
                                            ) : null}
                                            {allowed_feature_actions
                                                ?.eu_price_planning?.edit &&
                                            selectedRows.length > 1 ? (
                                                <Tooltip title="Bulk edit materials">
                                                    <Button
                                                        variant="contained"
                                                        disabled={
                                                            selectedRows.length ===
                                                                1 ||
                                                            !allowed_feature_actions
                                                                ?.eu_price_planning
                                                                ?.edit ||
                                                            selectedRows.find(
                                                                (sr) =>
                                                                    sr.data
                                                                        .approval_status %
                                                                        4 ===
                                                                    2
                                                            ) ||
                                                            regionalPricingAlertsConfigLoader !==
                                                                0
                                                        }
                                                        onClick={
                                                            onMaterialBulkEditClick
                                                        }
                                                    >
                                                        <EditNoteOutlinedIcon fontSize="small" />
                                                    </Button>
                                                </Tooltip>
                                            ) : null}
                                            {allowed_feature_actions
                                                ?.eu_price_planning
                                                ?.sync_to_gfe && !editMode ? (
                                                <>
                                                    <Tooltip
                                                        title="Sync to GFE"
                                                        enterDelay={300}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            onClick={
                                                                onSyncToGFEClick
                                                            }
                                                            disabled={
                                                                editMode ||
                                                                !allowed_feature_actions
                                                                    ?.eu_price_planning
                                                                    ?.sync_to_gfe ||
                                                                regionalPricingAlertsConfigLoader !==
                                                                    0
                                                            }
                                                        >
                                                            <ArrowCircleRightOutlinedIcon fontSize="small" />
                                                        </Button>
                                                    </Tooltip>
                                                    <ClearAllFilterButton
                                                        isVisible={
                                                            isAnyFilterApplied
                                                        }
                                                        agGridApi={
                                                            gridRef?.current
                                                                ?.api
                                                        }
                                                        onClick={() => {
                                                            setShowFlag(false);
                                                            setSelectedLockStatusOptions(
                                                                []
                                                            );
                                                            setSelectedExceptionOptions(
                                                                []
                                                            );
                                                            setSelectedAlertOptions(
                                                                []
                                                            );
                                                        }}
                                                    />
                                                </>
                                            ) : null}
                                            {allowed_feature_actions
                                                ?.eu_price_planning?.edit ? (
                                                <Tooltip
                                                    title="Enter Edit Mode"
                                                    enterDelay={300}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        onClick={onEditClick}
                                                        sx={{ mr: 0 }}
                                                        disabled={
                                                            !allowed_feature_actions
                                                                ?.eu_price_planning
                                                                ?.edit ||
                                                            regionalPricingAlertsConfigLoader !==
                                                                0 ||
                                                            selectedRows.length >
                                                                1 ||
                                                            selectedRows[0].data
                                                                .approval_status %
                                                                4 ===
                                                                2 ||
                                                            !Object.values(
                                                                selectedRows[0]
                                                                    .data
                                                            ).find((pl) => {
                                                                return (
                                                                    pl &&
                                                                    ((pl.is_msrp_locked !==
                                                                        undefined &&
                                                                        !pl.is_msrp_locked) ||
                                                                        (pl.is_whsl_locked !==
                                                                            undefined &&
                                                                            !pl.is_whsl_locked))
                                                                );
                                                            })
                                                        }
                                                    >
                                                        <EditOutlinedIcon fontSize="small" />
                                                    </Button>
                                                </Tooltip>
                                            ) : null}
                                            {allowed_feature_actions
                                                ?.eu_price_planning
                                                ?.download ? (
                                                <Button
                                                    variant="contained"
                                                    onClick={handleClick}
                                                    sx={{ mr: 0 }}
                                                    disabled={
                                                        !allowed_feature_actions
                                                            ?.eu_price_planning
                                                            ?.download
                                                    }
                                                >
                                                    <DownloadOutlinedIcon fontSize="small" />
                                                </Button>
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            <span>
                                                <Switch
                                                    checked={showFlag}
                                                    onChange={() =>
                                                        setShowFlag(!showFlag)
                                                    }
                                                />{" "}
                                                <span
                                                    className={`select-label no-wrap ${styles["input-label"]}`}
                                                    style={{
                                                        display: "inline",
                                                    }}
                                                >
                                                    Show error flags
                                                </span>
                                            </span>
                                            <div className={`align-center`}>
                                                <span
                                                    className={`select-label no-wrap ${styles["input-label"]}`}
                                                >
                                                    Lock Status
                                                </span>
                                                <div style={{ width: "180px" }}>
                                                    <Select
                                                        className="input"
                                                        initialData={
                                                            lockStatusOptions
                                                        }
                                                        selectedOptions={
                                                            selectedLockStatusOptions
                                                        }
                                                        updateSelected={(
                                                            data
                                                        ) =>
                                                            setSelectedLockStatusOptions(
                                                                data.selectedItems
                                                            )
                                                        }
                                                        is_multiple_selection
                                                        updateSelectedOnEachSelection
                                                    />
                                                </div>
                                            </div>
                                            <div className={`align-center`}>
                                                <span
                                                    className={`select-label no-wrap ${styles["input-label"]}`}
                                                >
                                                    Exceptions
                                                </span>
                                                <div style={{ width: "180px" }}>
                                                    <Select
                                                        className="input"
                                                        initialData={
                                                            exceptionOptions
                                                        }
                                                        selectedOptions={
                                                            selectedExceptionOptions
                                                        }
                                                        updateSelected={(
                                                            data
                                                        ) =>
                                                            setSelectedExceptionOptions(
                                                                data.selectedItems
                                                            )
                                                        }
                                                        is_multiple_selection
                                                        isDisabled={
                                                            regionalPricingAlertsConfigLoader !==
                                                            0
                                                        }
                                                        updateSelectedOnEachSelection
                                                    />
                                                </div>
                                                {regionalPricingAlertsConfigLoader !==
                                                0 ? (
                                                    <RotateRightIcon
                                                        className={`${styles["load-indicator"]}`}
                                                        titleAccess="Loading exceptions"
                                                    />
                                                ) : null}
                                            </div>
                                            <div className={`align-center`}>
                                                <span
                                                    className={`select-label no-wrap ${styles["input-label"]}`}
                                                >
                                                    Alerts
                                                </span>
                                                <div style={{ width: "180px" }}>
                                                    <Select
                                                        className="input"
                                                        initialData={
                                                            alertOptions
                                                        }
                                                        selectedOptions={
                                                            selectedAlertOptions
                                                        }
                                                        updateSelected={(
                                                            data
                                                        ) =>
                                                            setSelectedAlertOptions(
                                                                data.selectedItems
                                                            )
                                                        }
                                                        is_multiple_selection
                                                        isDisabled={
                                                            regionalPricingAlertsConfigLoader !==
                                                            0
                                                        }
                                                        updateSelectedOnEachSelection
                                                    />
                                                </div>
                                                {regionalPricingAlertsConfigLoader !==
                                                0 ? (
                                                    <RotateRightIcon
                                                        className={`${styles["load-indicator"]}`}
                                                        titleAccess="Loading alerts"
                                                    />
                                                ) : null}
                                            </div>
                                            <Tooltip title="Refresh Regional Pricing">
                                                <Button
                                                    variant="contained"
                                                    onClick={onRefreshClick}
                                                >
                                                    <RefreshOutlinedIcon fontSize="small" />
                                                </Button>
                                            </Tooltip>
                                            <ClearAllFilterButton
                                                isVisible={isAnyFilterApplied}
                                                agGridApi={
                                                    gridRef?.current?.api
                                                }
                                                onClick={() => {
                                                    setShowFlag(false);
                                                    setSelectedLockStatusOptions(
                                                        []
                                                    );
                                                    setSelectedExceptionOptions(
                                                        []
                                                    );
                                                    setSelectedAlertOptions([]);
                                                }}
                                            />
                                            {allowed_feature_actions
                                                ?.eu_price_planning
                                                ?.download ? (
                                                <Button
                                                    variant="contained"
                                                    onClick={handleClick}
                                                    sx={{ mr: 0 }}
                                                    disabled={
                                                        !allowed_feature_actions
                                                            ?.eu_price_planning
                                                            ?.download ||
                                                        !countryLevelPrices.length
                                                    }
                                                >
                                                    <DownloadOutlinedIcon fontSize="small" />
                                                </Button>
                                            ) : null}
                                            <Tooltip title="Table settings">
                                                <Button
                                                    variant="contained"
                                                    onClick={onSettingsClick}
                                                >
                                                    <SettingsIcon fontSize="small" />
                                                </Button>
                                            </Tooltip>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    <StyledMenu
                        id="regional-pricing-download-menu"
                        anchorEl={anchorEl}
                        open={downloadMenuOpen}
                        onClose={handleClose}
                        sx={{ margin: 0 }}
                    >
                        <MenuItem onClick={onDownloadTableDataClick}>
                            Table Data
                        </MenuItem>
                        <MenuItem onClick={onDownloadAuditLogClick}>
                            Audit Log
                        </MenuItem>
                    </StyledMenu>
                </div>
            </div>
            <div
                style={{ height: "calc(100vh - 340px)", marginTop: "15px" }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    rowData={countryLevelPrices}
                    columnDefs={colDefs}
                    rowHeight={rowHeight}
                    suppressRowClickSelection
                    suppressClickEdit
                    suppressMovableColumns
                    onSelectionChanged={onSelectionChanged}
                    rowSelection="multiple"
                    suppressCopyRowsToClipboard
                    defaultColDef={defaultColDef}
                    onModelUpdated={onTableDataChange}
                    enableRangeSelection={true}
                    isRowSelectable={isRowSelectable}
                    onFilterChanged={(e) => {
                        setFilterModel(e?.api?.getFilterModel());
                    }}
                    isExternalFilterPresent={() =>
                        (selectedLockStatusOptions.length &&
                            selectedLockStatusOptions.length !==
                                lockStatusOptions.length) ||
                        selectedExceptionOptions.length ||
                        selectedAlertOptions.length
                    }
                    doesExternalFilterPass={externalFilter}
                    excelStyles={excelStyles}
                    processCellForClipboard={processCellForClipboard}
                    onColumnResized={onColumnResize}
                    tooltipInteraction
                    tooltipShowDelay={500}
                    popupParent={document.body}
                />
            </div>
            <TableRowCount count={rowCount} selected={selectedRowCount} />
            {isAuditLogModalOpen ? (
                <AuditLogModal
                    isAuditLogModalOpen={isAuditLogModalOpen}
                    setIsAuditLogModalOpen={setIsAuditLogModalOpen}
                    auditLogSrc={auditLogSrc}
                    channel={selectedChannels[0].label}
                />
            ) : null}
            <Modal
                open={confirmGFESyncModalOpen}
                onClose={onConfirmGFESyncModalClose}
            >
                <div
                    className={`${styles["reset-modal-container"]} flex-column overflow-hidden`}
                >
                    <div className="flex-column">
                        <strong>{confirmGFEText}</strong>
                    </div>
                    <div
                        className={`${styles["reset-modal-actions-container"]} button-group-flex`}
                    >
                        <Button
                            variant="contained"
                            onClick={onConfirmGFESyncProceed}
                        >
                            Proceed
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={onConfirmGFESyncCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal open={isLockModalOpen} onClose={onLockModalClose}>
                <div
                    className={`${styles["reset-modal-container"]} flex-column overflow-hidden`}
                >
                    <div className="flex-column">
                        <strong>{toggleLockConfirmationText}</strong>
                    </div>
                    <div
                        className={`${styles["reset-modal-actions-container"]} button-group-flex`}
                        style={{ justifyContent: "flex-end" }}
                    >
                        <Button
                            variant="contained"
                            onClick={() =>
                                toggleLock(
                                    lockPayload.isLocking,
                                    lockPayload.type,
                                    lockPayload.pl,
                                    lockPayload.colType
                                )
                            }
                        >
                            Proceed
                        </Button>
                        <Button variant="outlined" onClick={onLockModalClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal open={bulkEditModalOpen} onClose={onBulkEditModalCancel}>
                <div
                    className={`${styles["bulk-edit-modal-container"]} flex-column `}
                >
                    <div className={styles["bulk-edit-modal-title"]}>
                        <span>
                            Bulk Edit Prices
                            <Tooltip title="The changes are applied on all the materials selected">
                                <InfoOutlinedIcon
                                    className={styles["info-bulk-edit"]}
                                    fontSize="small"
                                />
                            </Tooltip>
                        </span>
                        <button
                            className={styles["bulk-edit-modal-close-btn"]}
                            onClick={onBulkEditModalCancel}
                        >
                            &times;
                        </button>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div className={`${styles["date-section"]}`}>
                            <strong className={`${styles["title"]}`}>
                                {selectedChannels[0].label.includes("OUTLET")
                                    ? "PFS WAS"
                                    : "MSRP"}{" "}
                                Price Lists
                            </strong>
                            <div style={{ width: "250px" }}>
                                <Select
                                    initialData={
                                        keys
                                            .slice(1)
                                            .filter(
                                                (e) =>
                                                    !e.is_msrp_cloned &&
                                                    appliedPriceListTableSettings.view_data.columns.find(
                                                        (col) =>
                                                            col.name === e.key
                                                    )?.isSelected
                                            )
                                            .map((e) => ({
                                                value: e.key,
                                                label: e.key,
                                                ...e,
                                            })) || []
                                    }
                                    selectedOptions={bulkEditRtlPriceLists}
                                    updateSelected={(data) =>
                                        setBulkEditRtlPriceLists(
                                            data.selectedItems
                                        )
                                    }
                                    is_multiple_selection
                                />
                            </div>
                            <div style={{ width: "250px", marginTop: "12px" }}>
                                <strong className={`${styles["title"]}`}>
                                    {selectedChannels[0].label.includes(
                                        "OUTLET"
                                    )
                                        ? "PFS WAS"
                                        : "MSRP"}{" "}
                                    Price
                                </strong>
                                <TextField
                                    sx={{
                                        width: "100px",
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                display: "none",
                                            },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                    }}
                                    disabled={
                                        bulkEditRtlPriceLists.length === 0
                                    }
                                    value={bulkEditRtlValue}
                                    type="number"
                                    min={0}
                                    onChange={(e) => {
                                        const tempFunc =
                                            e.target.value > 0
                                                ? parseFloat(
                                                      parseFloat(
                                                          e.target.value
                                                      ).toFixed(2)
                                                  )
                                                : 0;
                                        const val = isNaN(
                                            parseFloat(e.target.value)
                                        )
                                            ? e.target.value
                                            : tempFunc;
                                        setBulkEditRtlValue(val);
                                    }}
                                />
                            </div>
                        </div>

                        <div className={`${styles["date-section"]}`}>
                            <strong className={`${styles["title"]}`}>
                                {selectedChannels[0].label.includes("OUTLET")
                                    ? "Store"
                                    : "WHSL"}{" "}
                                Price Lists
                            </strong>
                            <div style={{ width: "250px" }}>
                                <Select
                                    initialData={
                                        keys
                                            .filter(
                                                (e) =>
                                                    !e.is_wholesale_cloned &&
                                                    appliedPriceListTableSettings.view_data.columns.find(
                                                        (col) =>
                                                            col.name === e.key
                                                    )?.isSelected
                                            )
                                            .map((e) => ({
                                                value: e.key,
                                                label: e.key,
                                                ...e,
                                            })) || []
                                    }
                                    selectedOptions={bulkEditWhslPriceLists}
                                    updateSelected={(data) =>
                                        setBulkEditWhslPriceLists(
                                            data.selectedItems
                                        )
                                    }
                                    is_multiple_selection
                                />
                            </div>
                            <div style={{ width: "250px", marginTop: "12px" }}>
                                <strong className={`${styles["title"]}`}>
                                    {selectedChannels[0].label.includes(
                                        "OUTLET"
                                    )
                                        ? "Store"
                                        : "Wholesale"}{" "}
                                    Price
                                </strong>
                                <TextField
                                    sx={{
                                        width: "100px",
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                display: "none",
                                            },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                    }}
                                    value={bulkEditWhslValue}
                                    disabled={
                                        bulkEditWhslPriceLists.length === 0
                                    }
                                    type="number"
                                    min={0}
                                    onChange={(e) => {
                                        const tempFunc =
                                            e.target.value > 0
                                                ? parseFloat(
                                                      parseFloat(
                                                          e.target.value
                                                      ).toFixed(2)
                                                  )
                                                : 0;
                                        const val = isNaN(
                                            parseFloat(e.target.value)
                                        )
                                            ? e.target.value
                                            : tempFunc;
                                        setBulkEditWhslValue(val);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${styles["reset-modal-actions-container"]} button-group-flex`}
                        style={{ justifyContent: "end" }}
                    >
                        <Button
                            variant="text"
                            onClick={() => onBulkEditModalCancel()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            disabled={
                                (!bulkEditRtlValue &&
                                    bulkEditRtlPriceLists.length) ||
                                (!bulkEditWhslValue &&
                                    bulkEditWhslPriceLists.length) ||
                                (!bulkEditRtlPriceLists.length &&
                                    !bulkEditWhslPriceLists.length)
                            }
                            onClick={() => onBulkEditSubmit()}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            disabled={
                                (!bulkEditRtlValue &&
                                    bulkEditRtlPriceLists.length) ||
                                (!bulkEditWhslValue &&
                                    bulkEditWhslPriceLists.length) ||
                                (!bulkEditRtlPriceLists.length &&
                                    !bulkEditWhslPriceLists.length)
                            }
                            onClick={() => {
                                isContinueEditing = true;
                                onBulkEditSubmit();
                            }}
                        >
                            Save & Continue Editing
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={bulkEditConfirmModalOpen}
                onClose={bulkEditConfirmationCancel}
            >
                <div
                    className={`${styles["reset-modal-container"]} flex-column overflow-hidden`}
                >
                    <div className="flex-column align-center">
                        <strong style={{ marginBottom: "23px" }}>
                            Changing Prices
                        </strong>
                        <div className={`${styles["sub-title"]}`}>
                            Some prices are locked or in syncing and will be
                            skipped. Are you sure you want to proceed with bulk
                            edit?
                        </div>
                        <div
                            className={`${styles["warning-container"]}`}
                            style={{ display: "flex" }}
                        >
                            <div className={`${styles["icon"]} justify-center`}>
                                i
                            </div>
                            Only the unlocked and not syncing prices will be
                            updated
                        </div>
                    </div>
                    <div
                        className={`${styles["reset-modal-actions-container"]} button-group-flex`}
                        style={{ justifyContent: "end", marginTop: "16px" }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => onBulkEditSubmit(true)}
                        >
                            Change
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={bulkEditConfirmationCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
            {showTableSettings ? (
                <>
                    <div className="drawer-inner-full-height">
                        <TableSettings
                            regionalPriceSettings
                            priceListSettings
                            onApply={onTableSettingsApply}
                            onClose={onTableSettingsClose}
                            selectedChannels={selectedChannels}
                        />
                    </div>
                    <div className="drawer-inner-full-height-cover" />
                </>
            ) : null}
            {showSendForApproval ? (
                <>
                    <div className="drawer-inner-full-height sm">
                        <div
                            className={`${pageStyles["drawer-container"]} flex-column`}
                        >
                            <div
                                className={`${pageStyles["header"]} flex-shrink-0 center-space-between`}
                            >
                                <span className={`${pageStyles["title"]}`}>
                                    Send for Approval
                                </span>
                                <CloseIcon
                                    className={`${pageStyles["close-icon"]} pointer`}
                                    onClick={onSendForApprovalCloseClick}
                                />
                            </div>
                            <div
                                className={`${pageStyles["body"]} flex-grow-1 overflow-auto`}
                            >
                                <label
                                    className={`${pageStyles["sub-title"]} select-label mb-4`}
                                >
                                    Snapshot Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <TextField
                                    className="input"
                                    placeholder="Add snapshot name"
                                    autoFocus
                                    sx={{ marginBottom: "16px" }}
                                    value={snapshotName}
                                    error={snapshotNameTrimmed.length > 255}
                                    helperText={
                                        snapshotNameTrimmed.length > 255
                                            ? "Max 255 characters"
                                            : null
                                    }
                                    onChange={onSnapshotNameChange}
                                />
                                <label
                                    className={`${pageStyles["sub-title"]} select-label mb-4`}
                                >
                                    Approver Email ID{" "}
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                    className={`${pageStyles["dropdown"]} mb-16`}
                                    initialData={approverEmails.map((str) => ({
                                        label: str,
                                        value: str,
                                    }))}
                                    selectedOptions={
                                        selectedApproverMailIDOptions
                                    }
                                    updateSelected={
                                        onSelectedApproverMailIDOptionsChange
                                    }
                                    hideClearSelection
                                    updateSelectedOnEachSelection
                                />
                                <label
                                    className={`${pageStyles["sub-title"]} select-label mb-4`}
                                >
                                    CC To
                                </label>
                                <Select
                                    className={`${pageStyles["dropdown"]} mb-16`}
                                    initialData={ccEmails
                                        .filter(
                                            (cc) =>
                                                selectedApproverMailIDOptions[0]
                                                    ?.value !== cc
                                        )
                                        .map((str) => ({
                                            label: str,
                                            value: str,
                                        }))}
                                    selectedOptions={selectedCCMailIDOptions}
                                    updateSelected={
                                        onSelectedCCMailIDOptionsChange
                                    }
                                    updateSelectedOnEachSelection
                                    is_multiple_selection
                                />
                                <label
                                    className={`${pageStyles["sub-title"]} select-label mb-4`}
                                >
                                    Description{" "}
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <textarea
                                    className={`${pageStyles["paste-area"]}`}
                                    placeholder="Add the description here"
                                    value={approvalReqDesc}
                                    onChange={onApprovalReqDescChange}
                                ></textarea>
                                <RadioGroup
                                    row
                                    value={approvalReqType}
                                    onChange={onApprovalReqTypeChange}
                                    className={styles["radio-group"]}
                                    sx={{ marginTop: "32px" }}
                                >
                                    <FormControlLabel
                                        value={approvalReqTypes.SEND_NOW}
                                        control={<Radio size="small" />}
                                        label="Send Now"
                                    />
                                    <FormControlLabel
                                        value={approvalReqTypes.SCHEDULED_TIME}
                                        control={<Radio size="small" />}
                                        label="Scheduled Time"
                                    />
                                </RadioGroup>
                                {approvalReqType ===
                                approvalReqTypes.SCHEDULED_TIME ? (
                                    <div className={`${pageStyles["info"]}`}>
                                        * Materials are sent for approval every
                                        day at <strong>8:30 AM CET</strong>
                                    </div>
                                ) : null}
                            </div>
                            <div
                                className={`${pageStyles["action-container"]} flex-shrink-0 align-center`}
                            >
                                <Button
                                    variant="contained"
                                    disabled={areSendForApprovalInputsInvalid}
                                    onClick={onSendForApprovalSendClick}
                                >
                                    Send
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={onSendForApprovalCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="drawer-inner-full-height-cover" />
                </>
            ) : null}
        </div>
    );
}

export default EUCountryLevelPrices;

export function AuditLogModal(props) {
    const { isAuditLogModalOpen, setIsAuditLogModalOpen, auditLogSrc } = props;
    const { logType, screen } = auditLogSrc;

    const {
        workbench: {
            general: { selectedChannels },
        },
    } = useSelector((state) => state);
    const channel = selectedChannels?.[0]?.label;

    const gridRef = useRef();
    const dispatch = useDispatch();

    const [auditData, setAuditData] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const [
        rowCount,
        selectedRowCount,
        onTableModelUpdate,
        onTableSelectChange,
    ] = useTableRowCount();

    const auditLogColDefs = useMemo(() => {
        let returnVal = [
            {
                field: "new_value",
                headerName: "Value To",
                flex: auditLogSrc.type === "status" ? undefined : 0.75,
                cellRenderer({ value, valueFormatted }) {
                    return (
                        <LogValueRenderer
                            type={auditLogSrc.type}
                            value={value}
                            valueFormatted={valueFormatted}
                        />
                    );
                },
                cellStyle: { display: "flex", alignItems: "center" },
            },
            {
                field: "old_value",
                headerName: "Value From",
                flex: auditLogSrc.type === "status" ? undefined : 0.75,
                cellRenderer({ value, valueFormatted }) {
                    return (
                        <LogValueRenderer
                            type={auditLogSrc.type}
                            value={value}
                            valueFormatted={valueFormatted}
                        />
                    );
                },
                cellStyle: { display: "flex", alignItems: "center" },
            },
            {
                field: "updated_at",
                headerName: "Changed at",
                valueFormatter({ value }) {
                    return moment(value).format("MM/DD/YYYY HH:mm:ss");
                },
                flex: auditLogSrc.type === "status" ? undefined : 1,
            },
            {
                field: "updated_by",
                headerName: "Changed By",
                flex: auditLogSrc.type === "status" ? undefined : 1.25,
            },
            {
                field: "reason",
                flex: auditLogSrc.type === "status" ? undefined : 1,
            },
        ];

        if (auditLogSrc.type === "status")
            returnVal.push({
                field: "sync_with_gfe_error_message",
                headerName: "Error Message",
                valueFormatter: fallbackFormatter,
            });
              
        return returnVal;
    }, [auditLogSrc]);

    useEffect(() => {
        setShowLoader(true);

        let event = "";
        switch (auditLogSrc.type) {
            case "MSRP":
                switch (logType) {
                    case "price":
                        event =
                            screen === "base-pricing"
                                ? "log_price_file_material_msrp_price"
                                : "log_price_file_material_generated_msrp_price";
                        break;
                    case "discount":
                        event = "log_price_file_material_discount";
                        break;
                    case "store_price":
                        event = "log_price_file_material_store_price";
                        break;
                    case "similarMaterial":
                        event = "log_price_file_similar_material";
                        break;
                    case "pfsWasMaterial":
                        event = "log_price_file_pfs_was_material";
                        break;

                    case "effective_date":
                    default:
                        event = "log_price_file_material_detail_msrp_eff_date";
                }
                break;
            case "WHSL":
                switch (logType) {
                    case "price":
                        event =
                            screen === "base-pricing"
                                ? "log_price_file_material_wholesale_price"
                                : "log_price_file_material_generated_wholesale_price";
                        break;
                    case "store_price":
                        event =
                            "log_price_file_material_generated_wholesale_price";
                        break;
                    case "effective_date":
                    default:
                        event =
                            "log_price_file_material_detail_wholesale_eff_date";
                }
                break;
            case "status":
            default:
                event = "log_sync_to_gfe";
                break;
        }

        let price_list_name = null;
        if (
            logType === "price" ||
            logType === "discount" ||
            logType === "store_price"
        ) {
            price_list_name = auditLogSrc.price_list_name;
        }

        if (logType === "similarMaterial") {
            price_list_name = "SIMILAR_MATERIAL";
        }

        if (logType === "pfsWasMaterial") {
            price_list_name = "PFS_WAS_MATERIAL";
        }

        fetchAuditLogData({
            method_type: "GET",
            price_file_material_uid: auditLogSrc.data.uid,
            price_list_name,
            event,
        })
            .then((res) => {
                const sortedData = res.data.data.sort(
                    (obj1, obj2) =>
                        moment(obj2.updated_at) - moment(obj1.updated_at)
                );

                setAuditData(sortedData);
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
                setAuditData([]);
                dispatch(toastError(err?.message || err));
            });
    }, [auditLogSrc]);

    const currentDataLabel = () => {
        let labelStr = "Current ";
        labelStr += " ";
        if (auditLogSrc.type === "status") {
            labelStr += "";
        } else if (logType === "price") {
            labelStr += channel?.includes("OUTLET") ? "PFS Was" : "Price";
        } else if (logType === "effective-date") {
            labelStr += `${auditLogSrc.type} Effective Date`;
        } else if (logType === "store_price") {
            labelStr += "Store Price";
        } else if (logType === "discount") {
            labelStr += "Discount";
        } else if (logType === "similarMaterial") {
            labelStr += "Similar Material";
        } else if (logType === "pfsWasMaterial") {
            labelStr += "PFS Was Material";
        }

        return `${labelStr}:`;
    };

    return (
        <>
            {isAuditLogModalOpen ? (
                <>
                    <div
                        className="drawer-inner-full-height"
                        style={{ width: "calc(55% - 36px)" }}
                    >
                        <div className={styles["audit-log-modal"]}>
                            <div className={styles["audit-log-modal-header"]}>
                                {auditLogSrc.type === "status"
                                    ? "Sync Status "
                                    : "Price"}
                                {logType === "effective_date"
                                    ? " Effective Date"
                                    : ""}{" "}
                                History
                            </div>
                            <div
                                className={
                                    styles["audit-log-modal-close-button"]
                                }
                                onClick={() => setIsAuditLogModalOpen(false)}
                                onKeyDown={() => {}}
                            >
                                &times;
                            </div>

                            <div style={{ display: "flex" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <span
                                        className={styles["audit-log-info-key"]}
                                    >
                                        Material No:
                                        <span
                                            className={
                                                styles["audit-log-info-value"]
                                            }
                                        >
                                            {auditLogSrc.data.material_number}
                                        </span>
                                    </span>
                                    <span
                                        className={styles["audit-log-info-key"]}
                                    >
                                        Season Code:
                                        <span
                                            className={
                                                styles["audit-log-info-value"]
                                            }
                                        >
                                            {auditLogSrc.data.season_code}
                                        </span>
                                    </span>
                                    {logType === "price" ? (
                                        <span
                                            className={
                                                styles["audit-log-info-key"]
                                            }
                                        >
                                            Price List:
                                            <span
                                                className={
                                                    styles[
                                                        "audit-log-info-value"
                                                    ]
                                                }
                                            >
                                                {auditLogSrc.price_list_name}
                                            </span>
                                        </span>
                                    ) : null}
                                    <span
                                        className={styles["audit-log-info-key"]}
                                    >
                                        {currentDataLabel()}
                                        <span
                                            className={
                                                styles["audit-log-info-value"]
                                            }
                                        >
                                            {auditLogSrc.type === "status"
                                                ? syncStatusCodes[
                                                      auditLogSrc.value
                                                  ].text
                                                : auditLogSrc.value}
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div
                                style={{
                                    minHeight: "265px",
                                    marginTop: "2rem",
                                    maxHeight: "calc(100vh - 395px)",
                                    height: `calc(40px + calc(45 * ${auditData.length}px)`,
                                    position: "relative",
                                }}
                                className="ag-theme-alpine"
                            >
                                <LoadingOverlay
                                    showLoader={showLoader}
                                    isLocalLoader
                                />
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={auditData}
                                    columnDefs={auditLogColDefs}
                                    // autoSizeAllColumns
                                    suppressClickEdit
                                    defaultColDef={{
                                        valueFormatter: fallbackFormatter,
                                        resizable: true,
                                    }}
                                    onModelUpdated={onTableModelUpdate}
                                    onSelectionChanged={onTableSelectChange}
                                />
                            </div>
                            <TableRowCount
                                count={rowCount}
                                selected={selectedRowCount}
                            />
                        </div>
                    </div>
                    <div className="drawer-inner-full-height-cover" />
                </>
            ) : null}
        </>
    );
}
